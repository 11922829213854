import {
  AuthenticationResult,
  DelearDetailResult,
  SET_AUTHENTICATION_LOADING,
  SET_AUTHENTICATION_FAILED,
  RESET_AUTHENTICATION_ERROR_MESSAGE,
  SET_AUTHENTICATION_SUCCESS,
  SET_DEALER_DETAILS_LIST,
  SET_AUTHENTICATION_LOG_OUT,
  SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS,
  SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE,
  RESET_FORGET_PASSWORD_STATUS,
  SET_SELECTED_DEALER,
  SET_ELASTIC_INFO,
  SET_RESET_PASSWORD_FAILED,
  SET_RESET_PASSWORD_SUCCESS,
  AuthenticationActionTypes,
  SET_ACCOUNT_FEATURES,
  SET_IS_INTERNAL_DEALER,
  AccountFeature,
} from "../type/AuthenticationType";

export const setAuthenticaionLoading = (
  payload: boolean
): AuthenticationActionTypes => ({
  type: SET_AUTHENTICATION_LOADING,
  payload: payload,
});

export const setAuthenticationFailed = (
  payload: AuthenticationResult
): AuthenticationActionTypes => ({
  type: SET_AUTHENTICATION_FAILED,
  payload: payload,
});

export const resetAuthenticationErrorMessage = (): AuthenticationActionTypes => ({
  type: RESET_AUTHENTICATION_ERROR_MESSAGE,
});

export const setAuthenticationSuccess = (
  payload: AuthenticationResult
): AuthenticationActionTypes => ({
  type: SET_AUTHENTICATION_SUCCESS,
  payload: payload,
});

export const setDealerDetailList = (
  payload: DelearDetailResult[]
): AuthenticationActionTypes => ({
  type: SET_DEALER_DETAILS_LIST,
  payload: payload,
});

export const SetIsInternalDealer = (
  payload: boolean
): AuthenticationActionTypes => ({
  type: SET_IS_INTERNAL_DEALER,
  payload: payload,
});

export const setAuthenticationLogOut = (): AuthenticationActionTypes => ({
  type: SET_AUTHENTICATION_LOG_OUT,
});

export const setForgetPasswordEmailValidationSuccess = (
  payload: boolean
): AuthenticationActionTypes => ({
  type: SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS,
  payload: payload,
});

export const setForgetPasswordEmailValidationError = (
  payload: string
): AuthenticationActionTypes => ({
  type: SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE,
  payload: payload,
});

export const resetForgetPasswordStatus = (): AuthenticationActionTypes => ({
  type: RESET_FORGET_PASSWORD_STATUS,
});

export const setSelectedDealer = (
  payload: number | undefined
): AuthenticationActionTypes => ({
  type: SET_SELECTED_DEALER,
  payload: payload,
});

export const setElasticInfo = (payload: string): AuthenticationActionTypes => ({
  type: SET_ELASTIC_INFO,
  payload: payload,
});

export const setResetPasswordSuccess = (
  payload: string
): AuthenticationActionTypes => ({
  type: SET_RESET_PASSWORD_SUCCESS,
  payload: payload,
});

export const setResetPasswordFailed = (
  payload: string
): AuthenticationActionTypes => ({
  type: SET_RESET_PASSWORD_FAILED,
  payload: payload,
});
export const setAccountFeatures = (
  payload: AccountFeature[]
): AuthenticationActionTypes => ({
  type: SET_ACCOUNT_FEATURES,
  payload: payload,
});
