import {
  SET_IAS_MARKET_LOGGING_IN,
  SET_IAS_MARKET_LOGIN_FAILED,
  RESET_IAS_MARKET_ERROR,
  IasMarketActionTypes
} from "../type/IasMarketType";

export const setIasLoggingIn = (payload: boolean): IasMarketActionTypes => ({
  type: SET_IAS_MARKET_LOGGING_IN,
  payload: payload
});

export const setIasLoginFailed = (): IasMarketActionTypes => ({
  type: SET_IAS_MARKET_LOGIN_FAILED
});

export const resetIasMarketError = (): IasMarketActionTypes => ({
  type: RESET_IAS_MARKET_ERROR
});
