import { store } from "../reducer";
import { DelearDetailResult } from "../type/AuthenticationType";

type PermissionTypes = "RO_LISTING" | "FINANCE" | "CASH_OFFER" | "DD_BUY_BID";
type PermissionList = {
  [key in PermissionTypes]: number;
};
const PERMISSIONS: PermissionList = {
  RO_LISTING: 1,
  FINANCE: 2,
  CASH_OFFER: 3,
  DD_BUY_BID: 4,
};

export function hasPermission(type: PermissionTypes): boolean {
  const state = store.getState();
  const { dealers, selectedDealerAccountId } = state.auth;
  if (dealers && dealers.length && selectedDealerAccountId) {
    let accountFeatureIds: number[] = [];
    dealers.forEach((dealer: DelearDetailResult) => {
      if (
        dealer.AccountId === selectedDealerAccountId &&
        dealer.AccountFeatureIds
      ) {
        accountFeatureIds = dealer.AccountFeatureIds;
      }
    });

    if (accountFeatureIds.indexOf(PERMISSIONS[type]) > -1) {
      return true;
    }
  }
  return false;
}
