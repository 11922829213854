import {
  DealershipAnalyticState,
  DealershipAnalyticAction,
  SET_DEALERSHIP_DATA_FAILED_MESSAGE,
  SET_DEALERSHIP_IMPRESSION_SUCCESS,
  SET_DEALERSHIP_DATA_LOADING,
  SET_DEALERSHIP_DEAL_SUCCESS,
  SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS,
  SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS,
  SET_USER_ACTIVITY_COUNT_SUCCESS,
  SET_CTR_SUMMARY_LOADING,
  SET_CTR_SUMMARY_FAILED_MESSAGE,
  SET_CTR_SUMMARY_SUCCESS,
  SET_INVENTORY_SUMMARY_LOADING,
  SET_INVENTORY_SUMMARY_FAILED_MESSAGE,
  SET_INVENTORY_SUMMARY_SUCCESS,
  SET_DEALERSHIP_ACCOUNTS_LOADING,
  SET_DEALERSHIP_ACCOUNTS_SUCCESS,
  SET_DEALERSHIP_ACCOUNTS_FAILED,
  SET_SELECTED_DEALERS_FOR_ANALYTICS,
  SET_SELECTED_DEALERS_FOR_INVENTORY,
} from "../type/DealerShipAnalyticType";

const initialState: DealershipAnalyticState = {
  dealershipErrorMessage: null,
  isDealershipLoading: false,
  selectedDealerAccounts: [],
  selectedDealerAccountsForInventory: [],
  dealChartData: [],
  impressionChartData: [],
  detailPageViewChartData: [],
  customerActivityChartData: {
    keys: [],
    data: [],
  },

  ctrSummaryErrorMessage: null,
  isCTRSummaryLoading: false,
  ctrSummary: {
    impressionsCount: null,
    clicksCount: null,
  },

  inventorySummaryErrorMessage: null,
  isInventorySummaryLoading: false,
  inventorySummary: {
    usedCount: null,
    newCount: null,
  },

  userActivityCount: {},

  dealershipAccounts: [],
  isLoadingDealershipAccounts: false,
  dealershipAccountsErrorMessage: null,
};

const iasDealershipReducer = (
  state = initialState,
  action: DealershipAnalyticAction
): DealershipAnalyticState => {
  switch (action.type) {
    case SET_DEALERSHIP_IMPRESSION_SUCCESS:
      return { ...state, impressionChartData: action.payload };
    case SET_DEALERSHIP_DEAL_SUCCESS:
      return { ...state, dealChartData: action.payload };
    case SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS:
      return { ...state, detailPageViewChartData: action.payload };
    case SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS:
      return { ...state, customerActivityChartData: action.payload };
    case SET_USER_ACTIVITY_COUNT_SUCCESS:
      return { ...state, userActivityCount: action.payload };
    case SET_DEALERSHIP_DATA_LOADING:
      return { ...state, isDealershipLoading: action.payload };
    case SET_DEALERSHIP_DATA_FAILED_MESSAGE:
      return { ...state, dealershipErrorMessage: action.payload };
    case SET_CTR_SUMMARY_LOADING:
      return { ...state, isCTRSummaryLoading: action.payload };
    case SET_CTR_SUMMARY_FAILED_MESSAGE:
      return { ...state, ctrSummaryErrorMessage: action.payload };
    case SET_CTR_SUMMARY_SUCCESS:
      return {
        ...state,
        ctrSummary: { ...action.payload },
        ctrSummaryErrorMessage: null,
      };
    case SET_INVENTORY_SUMMARY_LOADING:
      return {
        ...state,
        isInventorySummaryLoading: action.payload,
        inventorySummaryErrorMessage: null,
      };
    case SET_INVENTORY_SUMMARY_FAILED_MESSAGE:
      return { ...state, inventorySummaryErrorMessage: action.payload };
    case SET_INVENTORY_SUMMARY_SUCCESS:
      return {
        ...state,
        inventorySummary: { ...action.payload },
        inventorySummaryErrorMessage: null,
      };

    case SET_DEALERSHIP_ACCOUNTS_LOADING:
      return { ...state, isDealershipLoading: action.payload };
    case SET_DEALERSHIP_ACCOUNTS_SUCCESS:
      return { ...state, dealershipAccounts: action.payload };
    case SET_DEALERSHIP_ACCOUNTS_FAILED:
      return { ...state, dealershipAccountsErrorMessage: action.payload };

    case SET_SELECTED_DEALERS_FOR_ANALYTICS:
      return { ...state, selectedDealerAccounts: action.payload }
    case SET_SELECTED_DEALERS_FOR_INVENTORY:
      return { ...state, selectedDealerAccountsForInventory: action.payload }
    default:
      return state;
  }
};

export default iasDealershipReducer;
