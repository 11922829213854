import {
  InventoryState,
  InventoryAction,
  SET_GET_INVENTORY_LOADING,
  SET_GET_INVENTORY_FAILED,
  SET_GET_INVENTORY_SUCCESS,
  SET_UPDATE_INVENTORY_LOADING,
  SET_UPDATE_INVENTORY_FAILED,
  SET_UPDATE_INVENTORY_SUCCESS,
  SET_VEHICLE_DELETE_FAILED,
  SET_VEHICLE_DELETE_LOADING,
  SET_ADD_INVENTORY_LOADING,
  SET_CLEAR_INVENTORY_MESSAGES,
  SET_ADD_INVENTORY_SUCCESS,
  SET_ADD_INVENTORY_FAILED,
  SET_INVENTORY_EDITED_IMG_UPLOAD_FAILED,
  SET_INVENTORY_EDITED_IMG_UPLOAD_SUCCESS,
  SET_INVENTORY_EDITED_IMG_UPLOADING,
} from "../type/InventoryType";

const initialState: InventoryState = {
  loadingInventory: false,
  inventoryErrorMessage: null,
  inventories: [],
  updateInventorySuccessMessage: null,
  updateInventoryErrorMessage: null,
  updateInventoryLoading: false,
  deleteVehicleLoading: false,
  deleteVehicleErrorMessage: null,
  addInventoryLoading: false,
  addInventoryStatus: false,
  editedImageUploading: false,
  editedImage: null,
  editedImageUploadError: null,
};

const inventoryReducer = (
  state = initialState,
  action: InventoryAction
): InventoryState => {
  switch (action.type) {
    case SET_CLEAR_INVENTORY_MESSAGES:
      return {
        ...state,
        inventoryErrorMessage: null,
        deleteVehicleErrorMessage: null,
        updateInventoryErrorMessage: null,
        updateInventorySuccessMessage: null,
        editedImageUploadError: null,
      };
    case SET_VEHICLE_DELETE_FAILED:
      return {
        ...state,
        deleteVehicleLoading: false,
        deleteVehicleErrorMessage: action.payload,
      };
    case SET_VEHICLE_DELETE_LOADING:
      return {
        ...state,
        deleteVehicleLoading: action.payload,
      };
    case SET_GET_INVENTORY_LOADING:
      return {
        ...state,
        loadingInventory: action.payload,
        inventoryErrorMessage: null,
        deleteVehicleErrorMessage: null,
      };
    case SET_GET_INVENTORY_FAILED:
      return {
        ...state,
        loadingInventory: false,
        inventoryErrorMessage: action.payload,
      };
    case SET_GET_INVENTORY_SUCCESS:
      return { ...state, loadingInventory: false, inventories: action.payload };
    case SET_UPDATE_INVENTORY_LOADING:
      return {
        ...state,
        updateInventoryLoading: action.payload,
      };
    case SET_UPDATE_INVENTORY_FAILED:
      return {
        ...state,
        updateInventoryErrorMessage: action.payload,
        updateInventoryLoading: false,
      };

    case SET_UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        updateInventorySuccessMessage: action.payload,
        updateInventoryLoading: false,
      };
    case SET_ADD_INVENTORY_LOADING:
      return {
        ...state,
        addInventoryLoading: action.payload,
      };
    case SET_ADD_INVENTORY_SUCCESS:
      return {
        ...state,
        addInventoryStatus: action.payload,
      };
    case SET_ADD_INVENTORY_FAILED:
      return {
        ...state,
        addInventoryStatus: action.payload,
      };
    case SET_INVENTORY_EDITED_IMG_UPLOADING:
      return {
        ...state,
        editedImageUploading: action.uploading,
      };
    case SET_INVENTORY_EDITED_IMG_UPLOAD_SUCCESS:
      return {
        ...state,
        editedImage: action.updatedFile,
      };
    case SET_INVENTORY_EDITED_IMG_UPLOAD_FAILED:
      return {
        ...state,
        editedImageUploadError: action.errorMessage,
      };

    default:
      return state;
  }
};

export default inventoryReducer;
