export interface DealershipInfo {
  logo: string | null;
  name: string | null;
  parentAccountId: number | null;
  parentAccountName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  phone: string | null;
  email: string | null;
  url: string | null;
  website: string | null;
  videoUrl: string | null;
  message: string | null;
  uploadedDealerLogoImageUri?: string;
}

export interface ContactInfo {
  name: string | null;
  phone: string | null;
  email: string | null;
}

export interface FinancialAndAccountingInfo {
  financePortalUrl: string | null;
  accountingPortalUrl: string | null;
}

export interface AccountDetails {
  readonly dealershipInfo: DealershipInfo;
  readonly ownerInfo: ContactInfo;
  readonly itInfo: ContactInfo;
  readonly billingInfo: ContactInfo;
  readonly financialAndAccountingInfo: FinancialAndAccountingInfo;
}

export interface AccountDetailsState extends AccountDetails {
  loadingAccountDetails: boolean;
  error: string | null;
  selectedAccountForAccountDetails: number | null;
}

export const SET_ACCOUNT_DETAILS_LOADING = "SET_ACCOUNT_DETAILS_LOADING";
export const SET_ACCOUNT_DETAILS_FAILED = "SET_ACCOUNT_DETAILS_FAILED";
export const SET_ACCOUNT_DETAILS_SUCCESS = "SET_ACCOUNT_DETAILS_SUCCESS";
export const SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS =
  "SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS";

interface SetAccountDetailsLoading {
  type: typeof SET_ACCOUNT_DETAILS_LOADING;
  payload: boolean;
}

interface SetAccountDetailsSuccess {
  type: typeof SET_ACCOUNT_DETAILS_SUCCESS;
  payload: AccountDetails;
}

interface SetSelectedAccountForAccountDetails {
  type: typeof SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS;
  payload: number | null;
}

interface SetAccountDetailsFailed {
  type: typeof SET_ACCOUNT_DETAILS_FAILED;
  payload: string | null;
}

export type AccountDetailsAction =
  | SetAccountDetailsLoading
  | SetAccountDetailsSuccess
  | SetAccountDetailsFailed
  | SetSelectedAccountForAccountDetails;
