import React, { FC } from "react";
import { css } from "@emotion/core";

import {
  Card,
} from "antd";

export const EmCard: FC<any> = ({ children, ...props }) => {
  const { bordered = true, boxShadow = 'none' } = props

  return (
    <Card
      headStyle={{ border: '0px', padding: 'auto 36px' }}
      bodyStyle={{ padding: '3rem 1.5rem 3rem' }}
      style={{
        boxShadow
      }}
      bordered={bordered}
    >
      {children}
    </Card>
  )
}


