import {
  AccountInfoState,
  AccountDetailsAction,
  SET_ACCOUNT_DETAILS_LOADING,
} from "../type/CycleProType";

const initialState: AccountInfoState = {
  loadingAccountDetails: false,
  error: null,
  selectedAccountForAccountDetails: null,
  dealershipInfo: {
    name: null,
  },
};

const cycleProReducer = (
  state = initialState,
  action: AccountDetailsAction
): AccountInfoState => {
  switch (action.type) {
    case SET_ACCOUNT_DETAILS_LOADING:
      return {
        ...state,
        loadingAccountDetails: action.payload,
      };
    default:
      return state;
  }
};

export default cycleProReducer;