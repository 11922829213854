export interface IasMarketState {
  readonly isLoggingIn: boolean;
  readonly isFailed: boolean;
}

export const SET_IAS_MARKET_LOGGING_IN = "SET_IAS_MARKET_LOGGING_IN";
export const SET_IAS_MARKET_LOGIN_FAILED = "SET_IAS_MARKET_LOGIN_FAILED";
export const RESET_IAS_MARKET_ERROR = "RESET_IAS_MARKET_ERROR";

interface SetIasMarketLoggingInAction {
  type: typeof SET_IAS_MARKET_LOGGING_IN;
  payload: boolean;
}

interface SetIasMarketLogInFailedAction {
  type: typeof SET_IAS_MARKET_LOGIN_FAILED;
}

interface ResetIasMarketErrorAction {
  type: typeof RESET_IAS_MARKET_ERROR;
}

export type IasMarketActionTypes =
  | SetIasMarketLoggingInAction
  | SetIasMarketLogInFailedAction
  | ResetIasMarketErrorAction;
