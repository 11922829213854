export interface DealershipAnalyticState {
  readonly isDealershipLoading: boolean;
  readonly dealershipErrorMessage: string | null;
  readonly selectedDealerAccounts: number[];
  readonly selectedDealerAccountsForInventory: number[];
  readonly dealChartData: DealershipCommonDateCount[];
  readonly impressionChartData: DealershipCommonDateCount[];
  readonly detailPageViewChartData: DealershipCommonDateCount[];
  readonly customerActivityChartData: DealershipCustomerActivityDateCount;
  readonly userActivityCount: UserActivityCount;

  readonly isCTRSummaryLoading: boolean;
  readonly ctrSummaryErrorMessage: string | null;
  readonly ctrSummary: CTRSummaryData;

  readonly isInventorySummaryLoading: boolean;
  readonly inventorySummaryErrorMessage: string | null;
  readonly inventorySummary: InventorySummaryData;

  readonly isLoadingDealershipAccounts: boolean;
  readonly dealershipAccounts: string[];
  readonly dealershipAccountsErrorMessage: string | null;

}

export type ActionCategories =
  | "Impression"
  | "Details_Page_View"
  | "Customer_Activity"
  | "Dealership_Lead";

export type UserActivityCount = {
  [key: string]: number;
};

export interface DealershipCommonDateCount {
  date: string;
  displayDate: string;
  count: number;
}

export interface DealershipCustomerActivityDateCount {
  keys: string[];
  data: Array<{
    date: string;
    displayDate: string;
    [key: string]: string;
  }>;
}

export interface CTRSummaryData {
  impressionsCount: number | null;
  clicksCount: number | null;
}

export interface InventorySummaryData {
  usedCount: number | null;
  newCount: number | null;
}

export const SET_DEALERSHIP_DATA_LOADING = "SET_DEALERSHIP_DATA_LOADING";
export const SET_DEALERSHIP_DATA_FAILED_MESSAGE =
  "SET_DEALERSHIP_DATA_FAILED_MESSAGE";

export const SET_DEALERSHIP_DEAL_SUCCESS = "SET_DEALERSHIP_DEAL_SUCCESS";
export const SET_DEALERSHIP_IMPRESSION_SUCCESS =
  "SET_DEALERSHIP_IMPRESSION_SUCCESS";
export const SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS =
  "SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS";
export const SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS =
  "SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS";
export const SET_USER_ACTIVITY_COUNT_SUCCESS =
  "SET_USER_ACTIVITY_COUNT_SUCCESS";

export const SET_CTR_SUMMARY_LOADING = "SET_CTR_SUMMARY_LOADING";
export const SET_CTR_SUMMARY_FAILED_MESSAGE = "SET_CTR_SUMMARY_FAILED_MESSAGE";
export const SET_CTR_SUMMARY_SUCCESS = "SET_CTR_SUMMARY_SUCCESS";

export const SET_INVENTORY_SUMMARY_LOADING = "SET_INVENTORY_SUMMARY_LOADING";
export const SET_INVENTORY_SUMMARY_FAILED_MESSAGE =
  "SET_INVENTORY_SUMMARY_FAILED_MESSAGE";
export const SET_INVENTORY_SUMMARY_SUCCESS = "SET_INVENTORY_SUMMARY_SUCCESS";

export const SET_DEALERSHIP_ACCOUNTS_LOADING =
  "SET_DEALERSHIP_ACCOUNTS_LOADING";
export const SET_DEALERSHIP_ACCOUNTS_SUCCESS =
  "SET_DEALERSHIP_ACCOUNTS_SUCCESS";
export const SET_DEALERSHIP_ACCOUNTS_FAILED = "SET_DEALERSHIP_ACCOUNTS_FAILED";
export const SET_SELECTED_DEALERS_FOR_ANALYTICS = "SET_SELECTED_DEALERS_FOR_ANALYTICS";
export const SET_SELECTED_DEALERS_FOR_INVENTORY = "SET_SELECTED_DEALERS_FOR_INVENTORY";

interface SetDealershipFailedAction {
  type: typeof SET_DEALERSHIP_DATA_FAILED_MESSAGE;
  payload: string | null;
}

interface SetDealershipLoadingAction {
  type: typeof SET_DEALERSHIP_DATA_LOADING;
  payload: boolean;
}

interface SetDealershipImpressionSuccessAction {
  type: typeof SET_DEALERSHIP_IMPRESSION_SUCCESS;
  payload: DealershipCommonDateCount[];
}

interface SetDealershipDealsSuccessAction {
  type: typeof SET_DEALERSHIP_DEAL_SUCCESS;
  payload: DealershipCommonDateCount[];
}

interface SetDealershipDetailPageViewSuccess {
  type: typeof SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS;
  payload: DealershipCommonDateCount[];
}

interface SetDealershipCustomerActivitySuccess {
  type: typeof SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS;
  payload: DealershipCustomerActivityDateCount;
}

interface SetCTRSummaryFailedAction {
  type: typeof SET_CTR_SUMMARY_FAILED_MESSAGE;
  payload: string | null;
}

interface SetCTRSummaryLoadingAction {
  type: typeof SET_CTR_SUMMARY_LOADING;
  payload: boolean;
}

interface SetCTRSummarySuccess {
  type: typeof SET_CTR_SUMMARY_SUCCESS;
  payload: CTRSummaryData;
}

interface SetInventorySummaryFailedAction {
  type: typeof SET_INVENTORY_SUMMARY_FAILED_MESSAGE;
  payload: string | null;
}

interface SetInventorySummaryLoadingAction {
  type: typeof SET_INVENTORY_SUMMARY_LOADING;
  payload: boolean;
}

interface SetInventorySummarySuccess {
  type: typeof SET_INVENTORY_SUMMARY_SUCCESS;
  payload: InventorySummaryData;
}

interface SetUserActivityCountSuccess {
  type: typeof SET_USER_ACTIVITY_COUNT_SUCCESS;
  payload: UserActivityCount;
}

interface SetDealershipAccountsLoading {
  type: typeof SET_DEALERSHIP_ACCOUNTS_LOADING;
  payload: boolean;
}

interface SetDealershipAccountsSuccess {
  type: typeof SET_DEALERSHIP_ACCOUNTS_SUCCESS;
  payload: string[];
}

interface SetDealershipAccountsFailed {
  type: typeof SET_DEALERSHIP_ACCOUNTS_FAILED;
  payload: string | null;
}
interface SetDealershipAccountsForAnalytic {
  type: typeof SET_SELECTED_DEALERS_FOR_ANALYTICS;
  payload: number[];
}

interface SetDealershipAccountsForInventory {
  type: typeof SET_SELECTED_DEALERS_FOR_INVENTORY;
  payload: number[];
}

export type DealershipAnalyticAction =
  | SetDealershipImpressionSuccessAction
  | SetDealershipFailedAction
  | SetDealershipLoadingAction
  | SetDealershipDealsSuccessAction
  | SetDealershipDetailPageViewSuccess
  | SetDealershipCustomerActivitySuccess
  | SetCTRSummaryFailedAction
  | SetCTRSummaryLoadingAction
  | SetCTRSummarySuccess
  | SetInventorySummaryFailedAction
  | SetInventorySummaryLoadingAction
  | SetInventorySummarySuccess
  | SetUserActivityCountSuccess
  | SetDealershipAccountsLoading
  | SetDealershipAccountsSuccess
  | SetDealershipAccountsFailed
  | SetDealershipAccountsForAnalytic
  | SetDealershipAccountsForInventory;
