import React, { FC } from "react";
//import { css } from "@emotion/core";

import {
  Layout,
} from "antd";

export const EmLayout: FC<any> = ({ children, ...props }) => {
  const {
    idTag,
    background
  } = props

  return (
    <Layout
      id={idTag}
      style={{
        overflowX: 'hidden',
        padding: '0 4px',
        background: background || 'white',
      }}
    >
      {children}
    </Layout>
  )
}
