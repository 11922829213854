import {
  AccountDetailsState,
  AccountDetailsAction,
  SET_ACCOUNT_DETAILS_SUCCESS,
  SET_ACCOUNT_DETAILS_FAILED,
  SET_ACCOUNT_DETAILS_LOADING,
  SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS,
} from "../type/AccountDetailsType";

const initialState: AccountDetailsState = {
  loadingAccountDetails: false,
  error: null,
  selectedAccountForAccountDetails: null,
  dealershipInfo: {
    logo: null,
    name: null,
    parentAccountId: null,
    parentAccountName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    phone: null,
    email: null,
    url: null,
    website: null,
    videoUrl: null,
    message: null,
  },
  ownerInfo: {
    name: null,
    phone: null,
    email: null,
  },
  itInfo: {
    name: null,
    phone: null,
    email: null,
  },
  billingInfo: {
    name: null,
    phone: null,
    email: null,
  },
  financialAndAccountingInfo: {
    financePortalUrl: null,
    accountingPortalUrl: null,
  },
};

const accountDetailsReducer = (
  state = initialState,
  action: AccountDetailsAction
): AccountDetailsState => {
  switch (action.type) {
    case SET_ACCOUNT_DETAILS_LOADING:
      return {
        ...state,
        loadingAccountDetails: action.payload,
      };
    case SET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        dealershipInfo: { ...action.payload.dealershipInfo },
        ownerInfo: { ...action.payload.ownerInfo },
        itInfo: { ...action.payload.itInfo },
        billingInfo: { ...action.payload.billingInfo },
        financialAndAccountingInfo: {
          ...action.payload.financialAndAccountingInfo,
        },
      };
    case SET_ACCOUNT_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS:
      return { ...state, selectedAccountForAccountDetails: action.payload };
    default:
      return state;
  }
};

export default accountDetailsReducer;
