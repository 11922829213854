import { Vin } from './SharedTypes'

//@not verified
export interface Model {
  readonly Name: string;
  readonly Id: string;
}
//@not verified
export interface Trim {
  readonly Id: string;
  readonly Name: string;
  readonly DriveTrain: string;
  readonly Transmission: string;
  readonly EngineDescription: string;
  readonly Cylinders: number;
  readonly HighwayFuelEconomy: number;
  readonly CityFuelEconomy: number;
  readonly Options: Option[];
}
//@not verified
export interface Option {
  readonly Name: string;
  readonly Code: string;
  readonly IsStandard: boolean;
}
//@not verified
export interface VinDecodeResult {
  readonly Decoder: string;
  readonly VIN: string;
  readonly Year: number;
  readonly Make: string;
  readonly Models: Model[];
  readonly Model: string;
  readonly Trims: Trim[];
  readonly Category: string;
  readonly Style: string;
  readonly FuelType: string;
  readonly Id: string;
}

export interface GeneticAction {
  type: string,
  payload: any
}

export interface GenericSetFunc {
  (payload: any): GeneticAction
}

export type AppraisalActionTypes = GeneticAction;

export interface GenericFetchState {
  isFetching: boolean,
  errorMessage: string
}

export interface DecodedVin {
  MarketingCategory: string,
  ItemTypeId: number,
  Decoder: string,
  VIN: Vin,
  Year: string,
  Make: string,
  Model: string,
  Category: string,
  Style: string,
  FuelType: string,
  Id: string,
  Trims: Trim,
  createdAt: Date,
}

export interface VinDecodeReducerState extends GenericFetchState {
  decodedVins: DecodedVin[],
  decodedVin: DecodedVin | null,
  resetStatus: 'resetting' | 'pending',
  modellist: [],
  payloadData: []
}

export const SET_VIN_DECODE_IS_FETCHING = 'SET_VIN_DECODE_IS_FETCHING',
  SET_VIN_DECODE_DECODED_VIN = "SET_VIN_DECODE_DECODED_VIN",
  SET_VIN_DECODE_RESET_STATUS = "SET_VIN_DECODE_RESET_STATUS",
  SET_VIN_DECODE_ERROR = "SET_VIN_DECODE_ERROR",
  RESET_VIN_DECODE_ERROR = "RESET_VIN_DECODE_ERROR",
  ADD_TO_DECODED_VIN_LIST = 'ADD_TO_DECODED_VIN_LIST',
  REMOVE_FROM_DECODED_VIN_LIST = 'REMOVE_FROM_DECODED_VIN_LIST',
  RESET_VIN_DECODE_STATE = 'RESET_VIN_DECODE_STATE',
  SET_MODELLIST = 'SET_MODELLIST',
  SET_PAYLOAD = 'SET_PAYLOAD'