import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authenticationReducer from "./AuthenticationReducer";
import combinedCreateCashOfferReducer from "./CombineCashOfferReducer";
import iasMarketReducer from "./IasMarketReducer";
import signupReducer from "./SignupReducer";
import acceptOfferReducer from "./AcceptOfferReducer";
import VinDecodeReducer from "./VinDecodeReducer";
import AppraisalReducer from "./AppraisalReducer";
import DealershipAnalyticReducer from "./DealershipAnalyticReducer";
import ClickThroughRateReducer from "./ClickThroughRateReducer";
import InventoryReducer from "./InventoryReducer";
import InventoryMetricReducer from "./InventoryMetricReducer";
import AdvancedInventoryMetricsReducer from "./AdvancedInventoryMetricsReducer";
import accountDetailsReducer from "./AccountDetailsReducer";
import leadReducer from "./LeadReducer";
import useReducer from "./UsersReducer";
import cycleProReducer from "./CycleProReducer";

const rootReducer = combineReducers({
  auth: authenticationReducer,
  cashOffer: combinedCreateCashOfferReducer,
  iasMarket: iasMarketReducer,
  signup: signupReducer,
  acceptCashOffer: acceptOfferReducer,
  vinDecode: VinDecodeReducer,
  appraisal: AppraisalReducer,
  dealershipAnalytic: DealershipAnalyticReducer,
  clickThroughRate: ClickThroughRateReducer,
  advancedInventoryMetrics: AdvancedInventoryMetricsReducer,
  inventory: InventoryReducer,
  inventoryMetric: InventoryMetricReducer,
  accountDetails: accountDetailsReducer,
  lead: leadReducer,
  users: useReducer,
  accountinfo: cycleProReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "cashOffer",
    "createMotorcycleOffer",
    "iasMarket",
    "signup",
    "acceptCashOffer",
    "vinDecode",
    "appraisal",
    "dealershipAnalytic",
    "inventoryReducer",
    "inventoryMetric",
    "lead",
    "accountinfo",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunkMiddleware)
    : composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export const persistor = persistStore(store);
