export interface Model {
  readonly Name: string;
  readonly Id: string;
}

export interface Option {
  readonly Name: string;
  readonly Code: string;
  readonly IsStandard: boolean;
}

export interface Trim {
  readonly Id: string;
  readonly Name: string;
  readonly DriveTrain: string;
  readonly Transmission: string;
  readonly EngineDescription: string;
  readonly Cylinders: number;
  readonly HighwayFuelEconomy: number;
  readonly CityFuelEconomy: number;
  readonly Options: Option[];
}

export interface VinDecodeResult {
  readonly Decoder: string;
  readonly VIN: string;
  readonly Year: number;
  readonly Make: string;
  readonly Models: Model[];
  readonly Model: string;
  readonly Trims: Trim[];
  readonly Category: string;
  readonly Style: string;
  readonly FuelType: string;
  readonly Id: string;
  readonly [key: string]: any;
}

export interface CreateCashOfferPayload {
  ZipCode: string;
  Color: string;
  CustomerNotes?: string;
  ItemTypeId: number;
  ItemYear: number;
  Make: string;
  Mileage: number;
  Decoder: string;
  Model: string;
  Vin: string;
  CategoryName: string;
  Id: string;
  MarketingCategory: "car" | "motorcycle" | "boat";
  DoesYourCarRun?: boolean;
  HasBeenOperated?: boolean;
  HasKeys?: boolean;
  HasDamage: boolean;
  IsTrade?: boolean;
  DamageDescription?: string;
  RunDescription?: string;
  HasBeenSmokedIn?: boolean;
  HaveOutstandingLoan?: boolean;
  ExteriorColor?: string;
  InteriorColor?: string;
  OverallConditionId?: number;
  MechanicalConditionId?: number;
  PhysicalConditionId?: number;
  TireConditionId?: number;
  CityMileage?: number;
  HighwayMileage?: number;
  DriveTrain?: string;
  EngineDescription?: string;
  EngineSize?: number;
  FuelType?: string;
  Transmission?: string;
  Trim?: string;
  RemainingLoanAmount?: number | null;
  Attachments?: { AttachmentTypeId: 17 | 3020 }[];
  ItemOptions?: {
    IsInstalled: boolean;
    IsStandard: boolean;
    OptionName: string;
  }[];
}

export interface CreateBoatCashOfferPayload {
  ZipCode: string;
  Color: string;
  CustomerNotes?: string;
  ItemTypeId: number;
  ItemYear: number;
  Make: string;
  Mileage: number;
  Hours: number;
  Decoder: string;
  Model: string;
  ModelTrimID: string;
  Series: string;
  Vin: string;
  CategoryName: string;
  Id: string;
  MarketingCategory: "car" | "motorcycle" | "boat";
  DoesYourCarRun?: boolean;
  HasBeenOperated?: boolean;
  HasKeys?: boolean;
  HasDamage: boolean;
  IsTrade?: boolean;
  DamageDescription?: string;
  RunDescription?: string;
  HasBeenSmokedIn?: boolean;
  HaveOutstandingLoan?: boolean;
  ExteriorColor?: string;
  InteriorColor?: string;
  OverallConditionId?: number;
  MechanicalConditionId?: number;
  PhysicalConditionId?: number;
  TireConditionId?: number;
  CityMileage?: number;
  HighwayMileage?: number;
  DriveTrain?: string;
  EngineDescription?: string;
  EngineSize?: number;
  FuelType?: string;
  Transmission?: string;
  Trim?: string;
  RemainingLoanAmount?: number | null;
  HPRange?: string;
  HasTrailer?: boolean;
  TrailerType?: string;
  TrailerLength?: string;
  TrailerYear?: number;
  BeamInches?: number;
  LengthInches?: number;
  HullMaterial?: string;
  Brakes?: number;
  NumEngines?: number;
  Weight?: number;
  ModelType?: string;
  Attachments?: { AttachmentTypeId: 17 | 3020 }[];
  ItemOptions?: {
    IsInstalled: boolean;
    IsStandard: boolean;
    OptionName: string;
  }[];
}

export type VehicleType = "car" | "motorcycle" | "boat";

export interface CreateCashOfferPageState {
  readonly vehicleType: VehicleType;
  readonly isPageLoading: boolean;
  readonly accountId: number;
  readonly isOfferCreatedSuccessfully: boolean;
  readonly vinDecodeResult?: VinDecodeResult;
  readonly errorMessage: string;
  readonly editedImageUploading: boolean;
  readonly editedImage: any;
  readonly editedImageUploadError: string | null;
  readonly imagesChanged: boolean;
}

export const SET_CASH_OFFER_PAGE_LOADING = "SET__CASH_OFFER_PAGE_LOADING";
export const SET_VIN_DECODE_RESULT = "SET_VIN_DECODE_RESULT";
export const SET_CREATE_CASH_OFFER_HAS_ERROR =
  "SET_CREATE_CASH_OFFER_HAS_ERROR";
export const RESET_CREATE_CASH_OFFER_ERROR = "RESET_CREATE_CASH_OFFER_ERROR";
export const SET_CASH_OFFER_CREATED_SUCCESSFULLY =
  "SET_CASH_OFFER_CREATED_SUCCESSFULLY";
export const RESET_CASH_OFFER_STATUS = "RESET_CASH_OFFER_STATUS";
export const SET_CASH_OFFER_EDITED_IMG_UPLOADING =
  "SET_CASH_OFFER_EDITED_IMG_UPLOADING";
export const SET_CASH_OFFER_EDITED_IMG_UPLOAD_SUCCESS =
  "SET_CASH_OFFER_EDITED_IMG_UPLOAD_SUCCESS";
export const SET_CASH_OFFER_EDITED_IMG_UPLOAD_ERROR =
  "SET_CASH_OFFER_EDITED_IMG_UPLOAD_ERROR";
export const SET_IMAGES_CHANGED = "SET_IMAGES_CHANGED";


interface SetImagesChnaged {
  type: typeof SET_IMAGES_CHANGED;
  vehicleType: VehicleType;
  payload: boolean;
}
interface SetCreateCashofferPageLoadingAction {
  type: typeof SET_CASH_OFFER_PAGE_LOADING;
  vehicleType: VehicleType;
  payload: boolean;
}

interface SetCreateCashofferVinDecodeResultAction {
  type: typeof SET_VIN_DECODE_RESULT;
  vehicleType: VehicleType;
  payload: VinDecodeResult;
}

interface SetCreateCashofferHasErrorAction {
  type: typeof SET_CREATE_CASH_OFFER_HAS_ERROR;
  vehicleType: VehicleType;
  payload: string;
}

interface ResetCreateCashofferErrorAction {
  type: typeof RESET_CREATE_CASH_OFFER_ERROR;
  vehicleType: VehicleType;
}

interface SetCashofferOfferCreatedSuccessfullyAction {
  type: typeof SET_CASH_OFFER_CREATED_SUCCESSFULLY;
  vehicleType: VehicleType;
}

interface ResetCreateCashOfferStatus {
  type: typeof RESET_CASH_OFFER_STATUS;
  vehicleType: VehicleType;
}

interface SetCashOfferEditedImageUploadingAction {
  type: typeof SET_CASH_OFFER_EDITED_IMG_UPLOADING;
  uploading: boolean;
  vehicleType: VehicleType;
}

interface SetCashOfferEditedImageUploadSuccessAction {
  type: typeof SET_CASH_OFFER_EDITED_IMG_UPLOAD_SUCCESS;
  updatedFile: any;
  vehicleType: VehicleType;
}

interface SetCashOfferEditedImageUploadErrorAction {
  type: typeof SET_CASH_OFFER_EDITED_IMG_UPLOAD_ERROR;
  errorMessage: string;
  vehicleType: VehicleType;
}

// Union action type
export type CreateCashOfferActionTypes =
  | SetCreateCashofferPageLoadingAction
  | SetCreateCashofferVinDecodeResultAction
  | SetCreateCashofferHasErrorAction
  | ResetCreateCashofferErrorAction
  | SetCashofferOfferCreatedSuccessfullyAction
  | ResetCreateCashOfferStatus
  | SetCashOfferEditedImageUploadSuccessAction
  | SetCashOfferEditedImageUploadErrorAction
  | SetCashOfferEditedImageUploadingAction
  | SetImagesChnaged;
