export interface DealershipInfo {
  name: string | null;
}
export interface AccountInfo {
  readonly dealershipInfo: DealershipInfo;
}
export interface AccountInfoState extends AccountInfo {
  loadingAccountDetails: boolean;
  error: string | null;
  selectedAccountForAccountDetails: number | null;
}
export const SET_ACCOUNT_DETAILS_LOADING = "SET_ACCOUNT_DETAILS_LOADING";

interface SetAccountDetailsLoading {
  type: typeof SET_ACCOUNT_DETAILS_LOADING;
  payload: boolean;
}
export type AccountDetailsAction =
  | SetAccountDetailsLoading