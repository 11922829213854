// Define type for redux store

export interface AuthenticationResult {
  readonly City: string;
  readonly DisplayName: string;
  readonly Email: string;
  readonly Email2: string;
  readonly ErrorDetails: string;
  readonly FirstName: string;
  readonly LastName: string;
  readonly Message: string;
  readonly Mobile: string;
  readonly OptinMarketingEmail: boolean;
  readonly State: string;
  readonly Success: boolean;
  readonly Token: string;
  readonly DealerJWTToken: string;
  readonly TokenExpireTime: string;
  readonly TokenValidPeriod: number;
  readonly UserId: string;
  readonly UserImage: string;
  readonly UserTypeId: number;
  readonly Uuid: string;
  readonly Zip: string;
  readonly esCred: string;
}

export interface DelearDetailResult {
  readonly AccountId: number;
  readonly AccountFeatureIds: number[];
  readonly AccountImage: string;
  readonly AccountName: string;
  readonly ParentAccountId: null | number;
  readonly ParentAccountName: null | string;
  readonly Address1: string;
  readonly City: string;
  readonly ContactName: string;
  readonly Country: string;
  readonly DealerShipLicenseExpiration: string;
  readonly DealershipLicenceNumber: string;
  readonly Email: string;
  readonly Error: string;
  readonly ErrorDetails: string;
  readonly Message: string;
  readonly Password: string;
  readonly PhoneNumber: string;
  readonly State: string;
  readonly TaxIdNumber: string;
  readonly UserId: number;
  readonly WebSite: string;
  readonly zipCode: string;
  readonly IsInternalDealer: boolean;
}

export interface DealerParentChild {
  readonly AccountName: string | null;
  readonly AccountId: number | null;
  readonly childrenIds: number[];
  readonly children: DelearDetailResult[];
}

export interface AccountFeature {
  AccountFeatureTypeId: number;
  AccountId: number;
  Feature: string;
  OptedIn: boolean;
  RelatedTo: number;
}

export interface AuthenticationState {
  readonly isLoading: boolean;
  readonly isLoggedIn: boolean;
  readonly failedErrorMessage?: string;
  readonly userUuid?: string;
  readonly userId?: string;
  readonly userTypeId?: number;
  readonly sessionToken?: string;
  readonly dealerJWTToken?: string;
  readonly userEmail?: string;
  readonly userFirstname?: string;
  readonly userLastName?: string;
  readonly selectedDealerAccountId?: number;
  readonly dealers: DelearDetailResult[];
  readonly forgetPasswordEmailValidationSuccess: boolean;
  readonly forgetPasswordEmailValidationError: string;
  readonly resetPasswordSuccess: null | string;
  readonly resetPasswordError: null | string;
  readonly esCred: string;
  readonly city?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly accountFeatures: AccountFeature[];
  readonly IsInternalDealer: boolean;
}

// Define action type constant
export const SET_AUTHENTICATION_LOADING =
  "SET_AUTHENTICATION_LOADING_ACTION_TYPE";
export const SET_AUTHENTICATION_FAILED = "SET_AUTHENTICATION_FAILED";
export const RESET_AUTHENTICATION_ERROR_MESSAGE =
  "RESET_AUTHENTICATION_ERROR_MESSAGE";
export const SET_AUTHENTICATION_SUCCESS = "SET_AUTHENTICATION_SUCCESS";
export const SET_DEALER_DETAILS_LIST = "SET_DEALER_DETAILS_LIST";
export const SET_AUTHENTICATION_LOG_OUT = "SET_AUTHENTICATION_LOG_OUT";
export const SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS =
  "SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS";
export const SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE =
  "SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE";
export const RESET_FORGET_PASSWORD_STATUS = "RESET_FORGET_PASSWORD_STATUS";
export const SET_SELECTED_DEALER = "SET_SELECTED_DEALER";
export const SET_ELASTIC_INFO = "SET_ELASTIC_INFO";
export const SET_IS_INTERNAL_DEALER = "SET_IS_INTERNAL_DEALER";

export const SET_RESET_PASSWORD_SUCCESS = "SET_RESET_PASSWORD_SUCCESS";
export const SET_RESET_PASSWORD_FAILED = "SET_RESET_PASSWORD_FAILED";
export const SET_ACCOUNT_FEATURES = "SET_ACCOUNT_FEATURES";

// Define action
interface SetAuthenticationLoadingAction {
  type: typeof SET_AUTHENTICATION_LOADING;
  payload: boolean;
}

interface SetAuthenticationFailedAction {
  type: typeof SET_AUTHENTICATION_FAILED;
  payload: AuthenticationResult;
}

interface ResetAuthenticationErrorMessageAction {
  type: typeof RESET_AUTHENTICATION_ERROR_MESSAGE;
}

interface SetAuthenticationSuccessAction {
  type: typeof SET_AUTHENTICATION_SUCCESS;
  payload: AuthenticationResult;
}

interface SetDealerDeailsListAction {
  type: typeof SET_DEALER_DETAILS_LIST;
  payload: DelearDetailResult[];
}

interface SetAuthenticationLogOutAction {
  type: typeof SET_AUTHENTICATION_LOG_OUT;
}

interface SetForgetPasswordEmailValidationSuccessAction {
  type: typeof SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS;
  payload: boolean;
}

interface SetForgetPasswordEmailValidationErrorMessageAction {
  type: typeof SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE;
  payload: string;
}

interface ResetForgetPasswordStatusAction {
  type: typeof RESET_FORGET_PASSWORD_STATUS;
}

interface SetSelectedDealerAction {
  type: typeof SET_SELECTED_DEALER;
  payload: number | undefined;
}

interface SetElasticInfoAction {
  type: typeof SET_ELASTIC_INFO;
  payload: string;
}

interface SetResetPasswordSuccess {
  type: typeof SET_RESET_PASSWORD_SUCCESS;
  payload: string;
}

interface SetResetPasswordFailed {
  type: typeof SET_RESET_PASSWORD_FAILED;
  payload: string;
}

interface SetAccountFeatures {
  type: typeof SET_ACCOUNT_FEATURES;
  payload: AccountFeature[];
}

interface SetIsInternalDealer {
  type: typeof SET_IS_INTERNAL_DEALER;
  payload: boolean;
}

export type AuthenticationActionTypes =
  | SetAuthenticationLoadingAction
  | SetAuthenticationFailedAction
  | ResetAuthenticationErrorMessageAction
  | SetAuthenticationSuccessAction
  | SetDealerDeailsListAction
  | SetAuthenticationLogOutAction
  | SetForgetPasswordEmailValidationSuccessAction
  | SetForgetPasswordEmailValidationErrorMessageAction
  | ResetForgetPasswordStatusAction
  | SetSelectedDealerAction
  | SetElasticInfoAction
  | SetResetPasswordSuccess
  | SetResetPasswordFailed
  | SetAccountFeatures
  | SetIsInternalDealer;
