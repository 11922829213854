import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import SystemState from "../type";
import {
  setIasLoggingIn,
  setIasLoginFailed,
  resetIasMarketError,
} from "../action/IasMarketAction";
import { environmentVariableList } from "../constant";

const env = environmentVariableList();

export const iasMarketLoginThunkAction = (
  openNewTab = true,
  redirectUrl = ""
): ThunkAction<void, SystemState, null, Action> => async (
  dispatch,
  getState
) => {
  dispatch(resetIasMarketError());
  dispatch(setIasLoggingIn(true));
  try {
    const state = getState();
    const { userId, userUuid, dealerJWTToken } = state.auth;
    let baseUrl = env.REACT_APP_IAS_MARKET_PLACE;

    const userAgentString = navigator.userAgent;
    const chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    if (redirectUrl) {
      if (!redirectUrl.includes("iaslogin.com")) {
        baseUrl = redirectUrl;
      } else {
        const url = `${redirectUrl!}${
          redirectUrl!.includes("?") ? "&" : "?"
        }code=${dealerJWTToken}`;

        if (safariAgent === true) {
          window.location.replace(url);
        } else {
          // window.open(url, openNewTab ? "_blank" : "_self");
          window.location.replace(url);
        }
      }
    }
    const iasLoginResponse = await fetch(
      `${env.REACT_APP_AUTHENTICATION_END_POINT}/api/authentication/DealerDirectLogin`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserId: userId,
          UserUuid: userUuid,
        }),
      }
    );
    const resJson = await iasLoginResponse.json();
    if (resJson.Success) {
      const { Token, RefreshToken: refreshToken } = resJson;
      const url = `${baseUrl}?ticket=${Token}&refreshToken=${refreshToken}&FirstTimeLogin=true`;

      if (safariAgent === true) {
        window.location.replace(url);
      } else {
        // window.open(url, openNewTab ? "_blank" : "_self");
        window.location.replace(url);
      }
      // window.open(url, openNewTab ? "_blank" : "_self");
    } else {
      dispatch(setIasLoginFailed());
    }
  } catch (error) {
    console.error("Failed to login to ias:", error);
    dispatch(setIasLoginFailed());
  }
  dispatch(setIasLoggingIn(false));
};
