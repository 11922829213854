import React, { Fragment, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Layout, Spin, Icon, notification } from "antd";
import { css } from "@emotion/core";
import { DelearDetailResult } from "../../type/AuthenticationType";

import AppFooter from "./AppFooter";
import AppHeader from "./AppHeader";
import { useDispatch } from "react-redux";
import { getAccountFeaturesThunk } from "../../thunk/AuthenticationThunk";

const Content = Layout.Content;

interface Props extends RouteComponentProps {
  children: React.ReactNode;
  dealerList: DelearDetailResult[];
  isIasMarketLogginIn: boolean;
  isIasMarketLoginFailed: boolean;
  handleIasLogin: () => void;
  resetIasError: () => void;
  isAuthorized: boolean;
  userDealerList: any[];

  logout: () => void;
  handleSelectDealer: (accountId: number) => void;
  handleSelectDealerForAnalytics: (accountId: number[]) => void;
}

const AppLayout: React.FC<Props> = ({
  children,
  isAuthorized,
  dealerList,
  logout,
  userDealerList,
  //isIasMarketLogginIn,
  isIasMarketLoginFailed,
  isIasMarketLogginIn,
  resetIasError,
  handleIasLogin,
  handleSelectDealer,
  handleSelectDealerForAnalytics,
  location,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccountFeaturesThunk());
  }, [dispatch]);

  if (location.pathname.includes("/portal"))
    return <Fragment>{children}</Fragment>;
  //@todo move callback calls from /home to here (IMPORTANT)
  //console.log('u', userDealerList, dealerList)
  return (
    <Spin spinning={isIasMarketLogginIn}>
      <Layout style={{ minHeight: "100vh", overflowX: "hidden" }}>
        <AppHeader
          isAuthorized={isAuthorized}
          dealerList={dealerList}
          logout={logout}
          userDealerList={userDealerList}
          handleIasLogin={handleIasLogin}
          handleSelectDealer={handleSelectDealer}
          handleSelectDealerForAnalytics={handleSelectDealerForAnalytics}
        />
        <Content
          css={css`
            display: flex;
            & > div {
              width: 100%;
            }
          `}
        >
          {/* <Spin
          spinning={isIasMarketLogginIn}
          size='large'
          indicator={<Icon type="loading-3-quarters" />}
        >
        </Spin> */}
          {children}
        </Content>
        <AppFooter />
        {isIasMarketLoginFailed &&
          notification.error({
            message: "Redirect Failed",
            description:
              "Sorry we are not able to redirect you now. Please try again later",
            onClose: resetIasError,
          })}
      </Layout>
    </Spin>
  );
};

export default withRouter(AppLayout);
