export interface AdvancedInventoryMetricsState {
  readonly isLoading: boolean;
  readonly errorMessage: string | null;

  readonly data: AdvancedInventoryMetricsDataPayload[];
}

export const SET_ADV_INV_METRICS_LOADING = "SET_ADV_INV_METRICS_LOADING";
export const SET_ADV_INV_METRICS_FAILED = "SET_ADV_INV_METRICS_FAILED";

export const SET_ADV_INV_METRICS_DATA_SUCCESS =
  "SET_ADV_INV_METRICS_DATA_SUCCESS";

interface SetAdvInvMetricsLoadingAction {
  type: typeof SET_ADV_INV_METRICS_LOADING;
  payload: boolean;
}

interface SetAdvInvMetricsFailedAction {
  type: typeof SET_ADV_INV_METRICS_FAILED;
  payload: string | null;
}

export interface AdvancedInventoryMetricsDataPayload {
  make: string;
  model?: string;
  trim?: string;
  documentCount: number;
  activityCount: number;
  hitCount?: number;
  years: string[];
}

interface SetAdvInvMetricsDataSuccess {
  type: typeof SET_ADV_INV_METRICS_DATA_SUCCESS;
  payload: AdvancedInventoryMetricsDataPayload[];
}

export type AdvInvMetricsAction =
  | SetAdvInvMetricsLoadingAction
  | SetAdvInvMetricsFailedAction
  | SetAdvInvMetricsDataSuccess;
