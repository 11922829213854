import {
  AdvancedInventoryMetricsState,
  AdvInvMetricsAction,
  SET_ADV_INV_METRICS_LOADING,
  SET_ADV_INV_METRICS_FAILED,
  SET_ADV_INV_METRICS_DATA_SUCCESS,
} from "../type/AdvancedInventoryMetricsType";

const initialState: AdvancedInventoryMetricsState = {
  isLoading: false,
  errorMessage: null,

  data: [],
};

const AdvancedInventoryMetricsReducer = (
  state: AdvancedInventoryMetricsState = initialState,
  action: AdvInvMetricsAction
) => {
  switch (action.type) {
    case SET_ADV_INV_METRICS_LOADING:
      return { ...state, isLoading: action.payload, errorMessage: null };
    case SET_ADV_INV_METRICS_FAILED:
      return { ...state, isLoading: false, errorMessage: action.payload };
    case SET_ADV_INV_METRICS_DATA_SUCCESS:
      return { ...state, isLoading: false, data: [...action.payload] };
    default:
      return state;
  }
};

export default AdvancedInventoryMetricsReducer;
