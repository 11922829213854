export const exteriorColorList = [
  "Black",
  "Blue",
  "Red",
  "Brown",
  "White",
  "Gray",
  "Silver",
  "Green",
  "Orange",
  "Yellow",
  "Gold",
  "Maroon",
  "Copper",
];

export const interiorColorList = [
  "Black",
  "Blue",
  "Red",
  "Brown",
  "White",
  "Tan",
  "Beige",
  "Gray",
  "Silver",
  "Green",
  "Orange",
  "Yellow",
  "Gold",
  "Maroon",
  "Copper",
];
export const breakpoints = {
  sm: "576px",
  md: "875px",
  mid: "1488px",
  lg: "1800px",
};

export const environmentVariableList = () => {
  if (window.location.hostname === "dealer.rumbleon.com" ||
    window.location.hostname === "ohiodealer.rumbleon.com" ||
    window.location.hostname === "virginiadealer.rumbleon.com"
  ) {
    return {
      REACT_APP_AUTHENTICATION_END_POINT: "https://accounts.rumbleon.com",
      REACT_APP_DEALER_LIST_END_POINT: "https://org.rumbleon.com",
      REACT_APP_INV_END_POINT:
        "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
      REACT_APP_INV_INDEX_NAME: "v4prod-analytics-useractivity",
      REACT_APP_INV_APP_NAME: "v4proddealer",
      REACT_APP_VIN_DECODE_END_POINT: "https://cashoffer.rumbleon.com",
      REACT_APP_CASH_OFFER_END_POINT: "https://cashoffer.rumbleon.com",
      REACT_APP_IAS_LOOKUP_END_POINT:
        "https://marketplace.rumbleon.com/Account/auctionaccesslookup",
      REACT_APP_IAS_MARKET_PLACE: "https://dealerdirect.rumbleon.com/login",
      REACT_APP_S3_LINK: "https://sellimg.rumbleon.com",
      COMPANY_LOGO_S3_BUCKET: "https://sellimg.rumbleon.com/dealerlogos",
      REACT_APP_INVENTORY_END_POINT: "https://inventory.rumbleon.com",
      REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://org.rumbleon.com",
      REACT_APP_INVENTORY_DEV_TEAM_END_POINT: "https://v2team.rumbleon.com",
      REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
      SIGN_UP_SF_LINK: "https://rumbleon.secure.force.com/RumbleonContactUs",
      TEAM_PORTAL_UPLOAD_LINK:
        "https://rumbleon.secure.force.com/TeamPortalUpload",
      P2_INVENTORY_LINK: "https://inventory.rumbleon.com",
      OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/v4prodcashoffer-ff",
      OMNISTORE_ES_BASIC: `Basic Y3ljbGVwcm8tcHJvZC1ybzpudyRiUiEyY3M4Nzk=`,
      RIDENOW_AUCTIONS_LINK: "https://www.ridenowauctions.com"
    };
  }
  if (window.location.hostname === "ddv2dealer.rumbleon.com") {
    return {
      REACT_APP_AUTHENTICATION_END_POINT: "https://accounts.rumbleon.com",
      REACT_APP_DEALER_LIST_END_POINT: "https://org.rumbleon.com",
      REACT_APP_INV_END_POINT:
        "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
      REACT_APP_INV_INDEX_NAME: "v4prod-analytics-useractivity",
      REACT_APP_INV_APP_NAME: "v4proddealer",
      REACT_APP_VIN_DECODE_END_POINT: "https://cashoffer.rumbleon.com",
      REACT_APP_CASH_OFFER_END_POINT: "https://cashoffer.rumbleon.com",
      REACT_APP_IAS_LOOKUP_END_POINT:
        "https://v2marketplace.rumbleon.com/Account/auctionaccesslookup",
      REACT_APP_IAS_MARKET_PLACE: "https://v2dealerdirect.rumbleon.com/login",
      REACT_APP_S3_LINK: "https://sellimg.rumbleon.com",
      COMPANY_LOGO_S3_BUCKET: "https://sellimg.rumbleon.com/dealerlogos",
      REACT_APP_INVENTORY_END_POINT: "https://inventory.rumbleon.com",
      REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://org.rumbleon.com",
      REACT_APP_INVENTORY_DEV_TEAM_END_POINT: "https://v2team.rumbleon.com",
      REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
      SIGN_UP_SF_LINK: "https://dlr-rumbleon.cs50.force.com/RumbleonContactUs",
      P2_INVENTORY_LINK: "https://inventory.rumbleon.com",
      OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/v4prodcashoffer-ff",
      OMNISTORE_ES_BASIC: `Basic Y3ljbGVwcm8tcHJvZC1ybzpudyRiUiEyY3M4Nzk=`,
      RIDENOW_AUCTIONS_LINK: "https://www.ridenowauctions.com"
    };
  }
  if (
    process.env.REACT_APP_DEV_ENV === "preprod" ||
    window.location.hostname === "v4pproddealer.rumbleon.com"
  ) {
    return {
      REACT_APP_AUTHENTICATION_END_POINT:
        "https://v4pprodaccounts.rumbleon.com",
      REACT_APP_DEALER_LIST_END_POINT: "https://v4pprodorg.rumbleon.com",
      REACT_APP_INV_END_POINT:
        "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
      REACT_APP_INV_INDEX_NAME: "v4pp-analytics-useractivity",
      REACT_APP_INV_APP_NAME: "v4ppdealer",
      REACT_APP_VIN_DECODE_END_POINT: "https://v4pprodcashoffer.rumbleon.com",
      REACT_APP_CASH_OFFER_END_POINT: "https://v4pprodcashoffer.rumbleon.com",
      REACT_APP_IAS_LOOKUP_END_POINT:
        "https://marketplace-qa.rumbleon.com/Account/auctionaccesslookup",
      REACT_APP_IAS_MARKET_PLACE:
        "https://v2pproddealerdirect.rumbleon.com/login",
      REACT_APP_S3_LINK: "https://sellimg.rumbleon.com",
      COMPANY_LOGO_S3_BUCKET: "https://sellimg.rumbleon.com/dealerlogos",
      REACT_APP_INVENTORY_END_POINT: "https://v4pprodinventory.rumbleon.com",
      REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://v4pprodorg.rumbleon.com",
      REACT_APP_INVENTORY_DEV_TEAM_END_POINT:
        "https://v4pprodteam.rumbleon.com",
      REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
      SIGN_UP_SF_LINK: "https://dlr-rumbleon.cs50.force.com/RumbleonContactUs",
      TEAM_PORTAL_UPLOAD_LINK:
        "https://dlr-rumbleon.cs50.force.com/TeamPortalUpload",
      P2_INVENTORY_LINK: "https://v4pprodinventory.rumbleon.com",
      OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/v4ppcashoffer-ff",
      OMNISTORE_ES_BASIC: `Basic cHAtcC1ybzpzYTJTTXY3cTUz`,
      RIDENOW_AUCTIONS_LINK: "https://ridenow-staging.auctionserver.net"
    };
  }
  if (
    process.env.REACT_APP_DEV_ENV === "qa" ||
    window.location.hostname === "v4qadealer.rumbleon.com"
  ) {
    return {
      REACT_APP_AUTHENTICATION_END_POINT: "https://v4qaaccounts.rumbleon.com",
      REACT_APP_DEALER_LIST_END_POINT: "https://v4qaorg.rumbleon.com",
      REACT_APP_INV_END_POINT:
        "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",
      REACT_APP_INV_INDEX_NAME: "v4qa-analytics-useractivity",
      REACT_APP_INV_APP_NAME: "v4qadealer",
      REACT_APP_VIN_DECODE_END_POINT: "https://v4qacashoffer.rumbleon.com",
      REACT_APP_CASH_OFFER_END_POINT: "https://v4qacashoffer.rumbleon.com",
      REACT_APP_IAS_LOOKUP_END_POINT:
        "https://marketplace-qa.rumbleon.com/Account/auctionaccesslookup",
      REACT_APP_IAS_MARKET_PLACE: "https://dealerdirect-testing.rumbleon.com",
      REACT_APP_S3_LINK: "https://qasellimg.rumbleon.com",
      COMPANY_LOGO_S3_BUCKET: "https://qasellimg.rumbleon.com/dealerlogos",
      REACT_APP_INVENTORY_END_POINT: "https://v4qainventory.rumbleon.com",
      REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://v4qaorg.rumbleon.com",
      REACT_APP_INVENTORY_DEV_TEAM_END_POINT: "https://v4qateam.rumbleon.com",
      REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
      SIGN_UP_SF_LINK: "https://qa1-rumbleon.cs11.force.com/RumbleonContactUs",
      TEAM_PORTAL_UPLOAD_LINK:
        "https://dlr-rumbleon.cs50.force.com/TeamPortalUpload",
      P2_INVENTORY_LINK: "https://v4pprodinventory.rumbleon.com",
      OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/v4ppcashoffer-ff",
      OMNISTORE_ES_BASIC: `Basic cHAtcC1ybzpzYTJTTXY3cTUz`,
      RIDENOW_AUCTIONS_LINK: "https://ridenow-staging.auctionserver.net"
    };
  }
  if (window.location.hostname === "ddv2qadealer.rumbleon.com") {
    return {
      REACT_APP_AUTHENTICATION_END_POINT: "https://v4qaaccounts.rumbleon.com",
      REACT_APP_DEALER_LIST_END_POINT: "https://v4qaorg.rumbleon.com",
      REACT_APP_INV_END_POINT:
        "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",
      REACT_APP_INV_INDEX_NAME: "v4qa-analytics-useractivity",
      REACT_APP_INV_APP_NAME: "v4qadealer",
      REACT_APP_VIN_DECODE_END_POINT: "https://v4qacashoffer.rumbleon.com",
      REACT_APP_CASH_OFFER_END_POINT: "https://v4qacashoffer.rumbleon.com",
      REACT_APP_IAS_LOOKUP_END_POINT:
        "https://marketplace-qa.rumbleon.com/Account/auctionaccesslookup",
      REACT_APP_IAS_MARKET_PLACE: "https://v4qadealerdirect.rumbleon.com/login",
      REACT_APP_S3_LINK: "https://qasellimg.rumbleon.com",
      COMPANY_LOGO_S3_BUCKET: "https://qasellimg.rumbleon.com/dealerlogos",
      REACT_APP_INVENTORY_END_POINT: "https://v4qainventory.rumbleon.com",
      REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://v4qaorg.rumbleon.com",
      REACT_APP_INVENTORY_DEV_TEAM_END_POINT: "https://v4qateam.rumbleon.com",
      REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
      SIGN_UP_SF_LINK: "https://dlr-rumbleon.cs50.force.com/RumbleonContactUs",
      P2_INVENTORY_LINK: "https://v4pprodinventory.rumbleon.com",
      OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/v4ppcashoffer-ff",
      OMNISTORE_ES_BASIC: `Basic cHAtcC1ybzpzYTJTTXY3cTUz`,
      RIDENOW_AUCTIONS_LINK: "https://ridenow-staging.auctionserver.net"
    };
  }
  if (
    process.env.REACT_APP_DEV_ENV === "demo" ||
    window.location.hostname === "demodealer.rumbleon.com"
  ) {
    return {
      REACT_APP_AUTHENTICATION_END_POINT: "https://demoaccounts.rumbleon.com",
      REACT_APP_DEALER_LIST_END_POINT: "https://demoorg.rumbleon.com",
      REACT_APP_INV_END_POINT:
        "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
      REACT_APP_INV_INDEX_NAME: "analytics-bweb-useractivity",
      REACT_APP_INV_APP_NAME: "demodealer",
      REACT_APP_VIN_DECODE_END_POINT: "https://democashoffer.rumbleon.com",
      REACT_APP_CASH_OFFER_END_POINT: "https://democashoffer.rumbleon.com",
      REACT_APP_IAS_LOOKUP_END_POINT:
        "https://marketplace-qa.rumbleon.com/Account/auctionaccesslookup",
      REACT_APP_IAS_MARKET_PLACE: "https://demodealerdirect.rumbleon.com/login",
      REACT_APP_S3_LINK: "https://sellimg.rumbleon.com",
      COMPANY_LOGO_S3_BUCKET: "https://sellimg.rumbleon.com/dealerlogos",
      REACT_APP_INVENTORY_END_POINT: "https://demoinventory.rumbleon.com",
      REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://demoorg.rumbleon.com",
      REACT_APP_INVENTORY_DEV_TEAM_END_POINT: "https://demoteam.rumbleon.com",
      REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
      SIGN_UP_SF_LINK: "https://demosfapi.rumbleon.com/RumbleonContactUs",
      TEAM_PORTAL_UPLOAD_LINK:
        "https://demosfapi.rumbleon.com/TeamPortalUpload",
      P2_INVENTORY_LINK: "https://v4pprodinventory.rumbleon.com",
      OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/democashoffer-ff",
      OMNISTORE_ES_BASIC: `Basic cHAtcC1ybzpzYTJTTXY3cTUz`,
      RIDENOW_AUCTIONS_LINK: "https://ridenow-staging.auctionserver.net"
    };
  }
  return {
    REACT_APP_AUTHENTICATION_END_POINT: "https://demoaccounts.rumbleon.com",
    REACT_APP_DEALER_LIST_END_POINT: "https://demoorg.rumbleon.com",
    REACT_APP_INV_END_POINT:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    REACT_APP_INV_INDEX_NAME: "analytics-bweb-useractivity",
    REACT_APP_INV_APP_NAME: "demodealer",
    REACT_APP_VIN_DECODE_END_POINT: "https://democashoffer.rumbleon.com",
    REACT_APP_CASH_OFFER_END_POINT: "https://democashoffer.rumbleon.com",
    REACT_APP_IAS_LOOKUP_END_POINT:
      "https://marketplace-qa.rumbleon.com/Account/auctionaccesslookup",
    REACT_APP_IAS_MARKET_PLACE: "https://demodealerdirect.rumbleon.com/login",
    REACT_APP_S3_LINK: "https://sellimg.rumbleon.com",
    COMPANY_LOGO_S3_BUCKET: "https://sellimg.rumbleon.com/dealerlogos",
    REACT_APP_INVENTORY_END_POINT: "https://demoinventory.rumbleon.com",
    REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://demoorg.rumbleon.com",
    REACT_APP_INVENTORY_DEV_TEAM_END_POINT: "https://demoteam.rumbleon.com",
    REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
    SIGN_UP_SF_LINK: "https://demosfapi.rumbleon.com/RumbleonContactUs",
    TEAM_PORTAL_UPLOAD_LINK:
      "https://demosfapi.rumbleon.com/TeamPortalUpload",
    P2_INVENTORY_LINK: "https://v4pprodinventory.rumbleon.com",
    OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/democashoffer-ff",
    OMNISTORE_ES_BASIC: `Basic cHAtcC1ybzpzYTJTTXY3cTUz`,
    RIDENOW_AUCTIONS_LINK: "https://ridenow-staging.auctionserver.net"


    //  PProd Settings
    // OMNISTORE_ES_URL: "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243/v4ppcashoffer-ff",
    // REACT_APP_AUTHENTICATION_END_POINT:
    //   "https://v4pprodaccounts.rumbleon.com",
    // REACT_APP_DEALER_LIST_END_POINT: "https://v4pprodorg.rumbleon.com",
    // REACT_APP_INV_END_POINT:
    //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    // REACT_APP_INV_INDEX_NAME: "v4pp-analytics-useractivity",
    // REACT_APP_INV_APP_NAME: "v4ppdealer",
    // REACT_APP_VIN_DECODE_END_POINT: "https://v4pprodcashoffer.rumbleon.com",
    // REACT_APP_CASH_OFFER_END_POINT: "https://v4pprodcashoffer.rumbleon.com",
    // REACT_APP_IAS_LOOKUP_END_POINT:
    //   "https://marketplace-qa.rumbleon.com/Account/auctionaccesslookup",
    // REACT_APP_IAS_MARKET_PLACE:
    //   "https://v2pproddealerdirect.rumbleon.com/login",
    // REACT_APP_S3_LINK: "https://sellimg.rumbleon.com",
    // COMPANY_LOGO_S3_BUCKET: "https://sellimg.rumbleon.com/dealerlogos",
    // REACT_APP_INVENTORY_END_POINT: "https://v4pprodinventory.rumbleon.com",
    // REACT_APP_ACCOUNT_DETAILS_END_POINT: "https://v4pprodorg.rumbleon.com",
    // REACT_APP_INVENTORY_DEV_TEAM_END_POINT:
    //   "https://v4pprodteam.rumbleon.com",
    // REACT_APP_INVENTORY_API_KEY: "38A20A3A-CC96-470E-AC85-AD219A4917AF",
    // SIGN_UP_SF_LINK: "https://dlr-rumbleon.cs50.force.com/RumbleonContactUs",
    // TEAM_PORTAL_UPLOAD_LINK:
    //   "https://dlr-rumbleon.cs50.force.com/TeamPortalUpload",
    // OMNISTORE_ES_BASIC: `Basic cHAtcC1ybzpzYTJTTXY3cTUz`
  };
};
