import {
  LeadState,
  LeadAction,
  SET_CLEAR_LEAD_MESSAGES,
  SET_GET_LEAD_LOADING,
  SET_GET_LEAD_FAILED,
  SET_GET_LEAD_SUCCESS,
  SET_GET_LEAD_LIST_LOADING,
  SET_GET_LEAD_LIST_FAILED,
  SET_GET_LEAD_LIST_SUCCESS,
  SET_SELL_TO_RUMBLEON_LOADING,
  SET_SELL_TO_RUMBLEON_FAILED,
  SET_NO_DATA_FOR_LEAD,
} from "../type/LeadType";

const initialState: LeadState = {
  leadList: [],
  leadListLoading: false,
  leadListError: null,

  lead: null,
  leadLoading: false,
  leadError: null,
  leadNoData: false,

  sellToRubmleonLoading: false,
  sellToRubmleonError: null,
};

const leadReducer = (state = initialState, action: LeadAction): LeadState => {
  switch (action.type) {
    case SET_CLEAR_LEAD_MESSAGES:
      return {
        ...state,
        leadListError: null,
        leadError: null,
        leadNoData: false,
        sellToRubmleonError: null,
      };

    case SET_GET_LEAD_LOADING:
      return { ...state, leadLoading: action.payload };
    case SET_GET_LEAD_FAILED:
      return { ...state, leadError: action.payload };
    case SET_GET_LEAD_SUCCESS:
      return { ...state, lead: action.payload };
    case SET_NO_DATA_FOR_LEAD:
      return { ...state, leadNoData: action.payload };

    case SET_GET_LEAD_LIST_LOADING:
      return { ...state, leadListLoading: action.payload };
    case SET_GET_LEAD_LIST_FAILED:
      return { ...state, leadListError: action.payload };
    case SET_GET_LEAD_LIST_SUCCESS:
      return { ...state, leadList: action.payload };

    case SET_SELL_TO_RUMBLEON_LOADING:
      return { ...state, sellToRubmleonLoading: action.payload };
    case SET_SELL_TO_RUMBLEON_FAILED:
      return { ...state, sellToRubmleonError: action.payload };

    default:
      return state;
  }
};

export default leadReducer;
