import {
  ClickThroughRateState,
  ClickThroughRateAction,
  SET_CTR_DATA_LOADING,
  SET_CTR_DATA_FAILED,
  SET_CTR_ALL_TIME_COUNT_SUCCESS,
  SET_CTR_DATA_SUCCESS,
} from "../type/ClickThroughRateType";

const initialState: ClickThroughRateState = {
  isLoading: false,
  errorMessage: null,

  allTimeCount: {
    impressions: null,
    clicks: null,
  },

  data: [],
};

const ClickThroughRateReducer = (
  state: ClickThroughRateState = initialState,
  action: ClickThroughRateAction
) => {
  switch (action.type) {
    case SET_CTR_DATA_LOADING:
      return { ...state, isLoading: action.payload, errorMessage: null };
    case SET_CTR_DATA_FAILED:
      return { ...state, isLoading: false, errorMessage: action.payload };
    case SET_CTR_ALL_TIME_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allTimeCount: { ...action.payload },
      };
    case SET_CTR_DATA_SUCCESS:
      return { ...state, isLoading: false, data: [...action.payload] };
    default:
      return state;
  }
};

export default ClickThroughRateReducer;
