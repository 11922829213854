import { capitalCase } from 'change-case';

export const nomalizeCapitalCase = (v: string) => {
  if (v.charAt(v.length - 2) !== " " && v.charAt(v.length - 1) === " ") {
    return v;
  }
  return capitalCase(v);
};

//in seconds
export const checkExpiration = (createdAt: number, TTL: number) => {
  const now = new Date();
  return Math.round(now.getTime() - createdAt) / 1000 > TTL;
};

export const findAndSplitArr = (list: object[], name: string, value: string) => {
  let rest: object[] = [], target: object | undefined;
  list.map((i: any) => {
    if (i[name] === value) {
      target = i;
    } else {
      rest.push(i);
    }
  })
  return { rest, target };
}

type ObjectStringKey = { [key: string]: any }
export const removePropFromObj = (obj: ObjectStringKey, key: string) => Object.keys(obj).reduce((acc: ObjectStringKey, cur: string) => {
  if (cur !== key) {
    acc[cur] = obj[cur];
  }
  return acc;
}, {})