export interface DealerOfferPayload {
  AppraisalId: number;
}

export interface AcceptCashOfferPayload {
  ApiKey: string;
  AppraisalRefNo: string;
  OfferPrice: number;
}

export interface DealerOfferResult {
  AppraisalId: number;
  AppraisalOfferId: number;
  AppraisalOfferStatusId: number;
  AppraisalRefNo: string;
  DealerEmail: string;
  DealerName: string;
  DealerPhoneNo: string;
  DealerShipAddress: string;
  DealerShipCity: string;
  DealerShipName: string;
  DealerShipState: string;
  Error: string;
  ErrorDetails: string;
  ExpiresOn: string;
  Make: string;
  MechanicalCondition: string;
  Message: string;
  Mileage: number;
  Model: string;
  OfferPrice: number;
  PhysicalCondition: string;
  TireCondition: string;
  Vin: string;
  year: number;
}

export interface AcceptCashOfferState {
  readonly isAcceptOfferLoading: boolean;
  readonly isGetDealerOfferDetailSuccess?: boolean;
  readonly isOfferAcceptSuccess?: boolean;
  readonly getDealerOfferDetailFailedErrorMessage: string;
  readonly acceptOfferFailedMessage?: string;
  readonly dealerOfferDeail?: DealerOfferResult;
}

export const SET_ACCEPT_OFFER_LOADING_ACTION =
  "SET_ACCEPT_OFFER_LOADING_ACTION";
export const SET_GET_DEALER_OFFER_DETAIL_SUCCESS_ACTION =
  "SET_GET_DEALER_OFFER_DETAIL_SUCCESS_ACTION";
export const SET_GET_DEALER_OFFER_DETAIL_FAILED_ERROR_MESSAGE_ACTION =
  "SET_GET_DEALER_OFFER_DETAIL_FAILED_ERROR_MESSAGE_ACTION";
export const SET_OFFER_ACCEPT_SUCCESS_ACTION =
  "SET_OFFER_ACCEPT_SUCCESS_ACTION";
export const SET_OFFER_ACCEPT_FAILED_MESSAGE_ACTION =
  "SET_OFFER_ACCEPT_FAILED_MESSAGE_ACTION ";
export const SET_DEALER_OFFER_DETAIL_ACTION = "SET_DEALER_OFFER_DETAIL_ACTION";
export const RESET_ACCEPT_OFFER_STATUS_ACTION =
  "RESET_ACCEPT_OFFER_STATUS_ACTION";

interface SetAcceptOfferLoadingAction {
  type: typeof SET_ACCEPT_OFFER_LOADING_ACTION;
  payload: boolean;
}

interface SetGetDealerOfferDetailSuccessAction {
  type: typeof SET_GET_DEALER_OFFER_DETAIL_SUCCESS_ACTION;
  payload: boolean;
}

interface SetGetDealerOfferDetailFailedErrorMessageAction {
  type: typeof SET_GET_DEALER_OFFER_DETAIL_FAILED_ERROR_MESSAGE_ACTION;
  payload: string;
}

interface SetOfferAcceptSuccessAction {
  type: typeof SET_OFFER_ACCEPT_SUCCESS_ACTION;
  payload: boolean;
}

interface SetOfferAcceptFailedMessageAction {
  type: typeof SET_OFFER_ACCEPT_FAILED_MESSAGE_ACTION;
  payload: string;
}

interface SetDealerOfferDetailAction {
  type: typeof SET_DEALER_OFFER_DETAIL_ACTION;
  payload: DealerOfferResult;
}

interface ResetAcceptCashOfferStatusAction {
  type: typeof RESET_ACCEPT_OFFER_STATUS_ACTION;
}

export type AcceptCashOfferActionTypes =
  | SetAcceptOfferLoadingAction
  | SetGetDealerOfferDetailSuccessAction
  | SetGetDealerOfferDetailFailedErrorMessageAction
  | SetOfferAcceptSuccessAction
  | SetOfferAcceptFailedMessageAction
  | SetDealerOfferDetailAction
  | ResetAcceptCashOfferStatusAction;
