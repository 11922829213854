import {
  IasMarketState,
  IasMarketActionTypes,
  SET_IAS_MARKET_LOGGING_IN,
  SET_IAS_MARKET_LOGIN_FAILED,
  RESET_IAS_MARKET_ERROR
} from "../type/IasMarketType";

const initialState: IasMarketState = {
  isFailed: false,
  isLoggingIn: false
};

const iasMarketReducer = (
  state = initialState,
  action: IasMarketActionTypes
): IasMarketState => {
  switch (action.type) {
    case SET_IAS_MARKET_LOGGING_IN:
      return {
        ...state,
        isLoggingIn: action.payload
      };
    case SET_IAS_MARKET_LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: false,
        isFailed: true
      };
    case RESET_IAS_MARKET_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default iasMarketReducer;
