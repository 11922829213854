import { Vin, VehicleType } from "./SharedTypes";

export const SET_APPRAISAL_IS_SUBMITTING = "SET_APPRAISAL_IS_SUBMITTING",
  SET_APPRAISAL_CURRENT_STEP = "SET_APPRAISAL_CURRENT_STEP",
  SET_APPRAISAL_COMPLETED_STEPS = "SET_APPRAISAL_COMPLETED_STEPS",
  SET_APPRAISAL_ERROR_MESSAGE = "SET_APPRAISAL_ERROR_MESSAGE",
  RESET_APPRAISAL_ERROR_MESSAGE = "SET_APPRAISAL_ERROR_MESSAGE",
  ADD_APPRAISAL_SUBMITTED_FORMS = "ADD_APPRAISAL_SUBMITTED_FORMS",
  SET_APPRAISAL_CURRENT_FORM = "SET_APPRAISAL_CURRENT_FORM",
  ADD_APPRAISAL_ATTACHMENTS = "ADD_APPRAISAL_ATTACHMENTS",
  REMOVE_APPRAISAL_ATTACHMENTS = "REMOVE_APPRAISAL_ATTACHMENTS",
  RESET_APPRAISAL_STATE = "RESET_APPRAISAL_STATE",
  ADD_CREATED_APPRAISAL = "ADD_CREATED_APPRAISAL",
  ADD_CREATED_APPRAISAL_VINLESS = "ADD_CREATED_APPRAISAL_VINLESS",
  REMOVE_CREATED_APPRAISAL = "REMOVE_CREATED_APPRAISAL",
  SET_APPRAISAL_CREATE_REQUEST = "SET_APPRAISAL_CREATE_REQUEST",
  SET_APPRAISAL_UPDATE_REQUEST = "SET_APPRAISAL_UPDATE_REQUEST",
  SET_APPRAISAL_IMAGE_UPLOAD_REQUEST = "SET_APPRAISAL_IMAGE_UPLOAD_REQUEST",
  SET_APPRAISAL_DEALER_EMAIL_REQUEST = "SET_APPRAISAL_DEALER_EMAIL_REQUEST",
  SET_APPRAISAL_ASSIGN_REQUEST = "SET_APPRAISAL_ASSIGN_REQUEST",
  SET_APPRAISAL_UPLOADED_IMAGES = "SET_APPRAISAL_UPLOADED_IMAGES",
  SET_APPRAISAL_DEALER_EMAIL = "SET_APPRAISAL_DEALER_EMAIL",
  SET_ISVINLESS = 'SET_ISVINLESS'

export type Submission = "success" | "pending" | "failure";
export type AppraisalRefs = { [key: string]: string };
export type AppraisalRefNo = string;

export const MOTORCYCLE_STEP_FIELDS: { [key: number]: string[] } = {
  2: ["ItemYear", "Make", "Model"],
  3: [
    "ZipCode",
    "Mileage",
    "Color",
    "HasDamage",
    "IsTrade",
    "DamageDescription",
    "RunDescription",
    "HasBeenOperated",
    "HasKeys",
    "OverallConditionId",
    "CustomerNotes",
    "Attachments",
  ],
  4: [
    "DealerUserFirstName",
    "DealerUserLastName",
    "DealerUserEmail",
    "DealerUserPhone",
  ],
};
export const MOTORCYCLE_VINLESSSTEP_FIELDS: { [key: number]: string[] } = {
  2: ["BikeYear", "BikeMake", "BikeModel"],
  3: [
    "ZipCode",
    "Mileage",
    "Color",
    "HasDamage",
    "IsTrade",
    "DamageDescription",
    "RunDescription",
    "HasBeenOperated",
    "HasKeys",
    "OverallConditionId",
    "CustomerNotes",
    "Attachments",
  ],
  4: [
    "DealerUserFirstName",
    "DealerUserLastName",
    "DealerUserEmail",
    "DealerUserPhone",
  ],
};
export const CAR_STEP_FIELDS: { [key: number]: string[] } = {
  2: ["ItemYear", "Make", "Model", "Trim"],
  3: [
    "ZipCode",
    "Mileage",
    "ExteriorColor",
    "InteriorColor",
    "ItemOptions",
    "HasDamage",
    "HasKeys",
    "IsTrade",
    "DamageDescription",
    "RunDescription",
    "HasBeenSmokedIn",
    "DoesYourCarRun",
    "PhysicalConditionId",
    "MechanicalConditionId",
    "TireConditionId",
    "CustomerNotes",
    "Attachments",
  ],
  4: [
    "DealerUserFirstName",
    "DealerUserLastName",
    "DealerUserEmail",
    "DealerUserPhone",
  ],
};
export const MARINES_STEP_FIELDS: { [key: number]: string[] } = {
  2: ["ItemYear", "Make", "Model", "Series"],
  3: [
    "ZipCode",
    "Hours",
    "Color",
    "HasDamage",
    "HasKeys",
    "HasBeenOperated",
    "IsTrade",
    "hpRange",
    "DamageDescription",
    "RunDescription",
    "OverallConditionId",
    "CustomerNotes",
    "Attachments",
    "HasTrailer",
    "trailerYear",
    "trailerType",
    "trailerLength",
  ],
  4: [
    "DealerUserFirstName",
    "DealerUserLastName",
    "DealerUserEmail",
    "DealerUserPhone",
  ],
};

export const APPRAISAL_STATUS_ID = 1;
export const APPRAISAL_SOURCE_TYPE_ID = 4;
export const APPRAISAL_ATTACHMENT_TYPE_ID: { [key: number]: 17 | 3020 | 3040 } = {
  1: 17,
  2: 3020,
  3: 3040,
};

export interface AppraisalBasicFormFields {
  Vin: Vin;
  Mileage: number;
  ZipCode: string;
  MarketingCategory: VehicleType;
  //
  Attachments?: { AttachmentTypeId: 17 | 3020 }[];
}

export interface CarFormFields { }

export interface MotorcycleFormFields { }
export interface MarinesFormFields { }

export interface AppraisalForm
  extends AppraisalBasicFormFields,
  CarFormFields,
  MarinesFormFields,
  MotorcycleFormFields {
  // Color: string;
  // CustomerNotes?: string;
  // ItemTypeId: number;
  // ItemYear: number;
  // Make: string;
  // Decoder: string;
  // Model: string;
  // CategoryName: string;
  // Id: string;
  // DoesYourCarRun?: boolean;
  // HasBeenOperated?: boolean;
  // HasDamage: boolean;
  // DamageDescription?: string;
  // HasBeenSmokedIn?: boolean;
  // ExteriorColor?: string;
  // InteriorColor?: string;
  // OverallConditionId?: number;
  // MechanicalConditionId?: number;
  // PhysicalConditionId?: number;
  // TireConditionId?: number;
  // CityMileage?: number;
  // HighwayMileage?: number;
  // DriveTrain?: string;
  // EngineDescription?: string;
  // EngineSize?: number;
  // FuelType?: string;
  // Transmission?: string;
  // Trim?: string;
  // HaveOutstandingLoan?: boolean;
  // RemainingLoanAmount?: number | null;
  // ItemOptions?: {
  //   IsInstalled: boolean;
  //   IsStandard: boolean;
  //   OptionName: string;
  // }[];
}

// type AssignParam = {
//   DealerUserFirstName: string,
//   DealerUserLastName: string,
//   DealerUserEmail: string,
//   DealerUserPhone: number,
// }

export type MappedTrimOptions = {
  OptionName: string;
  IsStandard: boolean;
  IsInstalled: boolean;
  OptionHeader: string;
  OptionCategory: string;
  OptionCode: number;
};

export type TrimOptions = {
  Name: string;
  IsStandard: boolean;
  Group: string;
  CategoryName: string;
  Code: number;
};
