import {
  SignupState,
  SignupActionTypes,
  SET_SIGNUP_SUCCESS,
  SET_SIGNUP_LOADING,
  SET_SIGNUP_FAILED_MESSAGE,
  RESET_SIGNUP_STATE,
} from "../type/SignupType";

const initialState: SignupState = {
  isSignupSuccess: false,
  isSignupLoading: false,
  signupFailedMessage: "",
};

const signupReducer = (
  state = initialState,
  action: SignupActionTypes,
): SignupState => {
  switch (action.type) {
    case SET_SIGNUP_SUCCESS:
      return {
        ...state,
        isSignupSuccess: action.payload,
      };
    case SET_SIGNUP_LOADING:
      return {
        ...state,
        isSignupLoading: action.payload,
      };
    case SET_SIGNUP_FAILED_MESSAGE:
      return {
        ...state,
        isSignupSuccess: false,
        signupFailedMessage: action.payload,
      };
    case RESET_SIGNUP_STATE:
      return initialState;
    default:
      return state;
  }
};

export default signupReducer;
