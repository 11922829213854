import React, { FC } from "react";
import { css } from "@emotion/core";
//import preval from "preval.macro";
import { Link } from "react-router-dom";
import { Row, Col, Layout, Icon, Button } from "antd";

import { ReactComponent as RumbleWhiteLogo } from "./asset/rumbleon-logo-white.svg";
import googleLogo from "./asset/google-play-logo.png";
import appleLogo from "./asset/apple-store-logo.png";

import { color, minMedia, maxMedia } from "../UiComponents";

const Footer = Layout.Footer;

const flexSharedCss = css`
  display: flex;
  justify-content: center;
  ${minMedia[1]} {
    justify-content: start;
  }
`;

const footerCss = css`
  color: ${color.textWhite};
  background: ${color.logoDark};
  padding: 1.5rem 2rem;
  button,
  a {
    color: ${color.textWhite};
  }
`;

const rowSpecial = css`
  ${flexSharedCss}
  ${maxMedia[1]} {
    flex-wrap: wrap;
    .ant-row:not(:last-child) {
      border-right: 1px solid ${color.borderDark};
    }
  }
`;
const bottomCss = css`
  border-top: 1px solid ${color.borderDark};
  margin-top: 1rem;
  padding-top: 0.5rem;
  ${minMedia[0]} {
    border-top: 0px;
  }
`;

const AppFooter: FC<any> = () => {
  //@todo move to about
  //const buildDate = () => preval`module.exports = new Date().toLocaleString("en-US", {timeZone: "America/Chicago"})`;

  return (
    <Footer css={footerCss}>
      <Row type="flex">
        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 8 }}>
          <Row css={flexSharedCss}>
            <RumbleWhiteLogo style={{ width: "200px" }} />
          </Row>
          <Row css={flexSharedCss}>
            <Button
              type="link"
              href="https://itunes.apple.com/us/app/rumbleon-dealer-direct/id1434578832"
              style={{ margin: "1rem 0", paddingLeft: "0px" }}
            >
              <img
                src={appleLogo}
                alt="Apple Store"
                style={{ width: "100px" }}
              />
            </Button>
            <Button
              type="link"
              href="https://play.google.com/store/apps/details?id=com.rumbleon.app"
              style={{ margin: "1rem 0", paddingLeft: "0px" }}
            >
              <img
                src={googleLogo}
                alt="Google Play"
                style={{ width: "100px" }}
              />
            </Button>
          </Row>
        </Col>
        <Col
          xs={{ span: 0 }}
          md={{ span: 6, offset: 2 }}
          lg={{ span: 6, offset: 2 }}
        >
          <Row>
            {/* <Link to="https://odoo.rumbleon.com/page/contactus"> */}
            <Button type="link" style={{ paddingLeft: "0px" }}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.rumbleon.com/termsofuse"
              >
                Terms and Conditions
              </a>
            </Button>
          </Row>

          <Row>
            <Button type="link" style={{ paddingLeft: "0px" }}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.rumbleon.com/privacy"
              >
                Privacy Policy
              </a>
            </Button>
          </Row>

          <Row>
            <span style={{ lineHeight: "2rem" }}>Contact</span>
          </Row>
          <Row>
            <Button
              type="link"
              href="tel:206-479-9772"
              style={{ paddingLeft: "0px" }}
            >
              <Icon type="phone" />
              (206) 479-9772
            </Button>
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 9, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          xl={{ span: 6, offset: 2 }}
        >
          <Row css={rowSpecial}>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 0 }}>
                <Button type="link" href="tel:206-479-9772">
                  <Icon type="phone" />
                  (206) 479-9772
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 0 }}>
                <Button type="link">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://odoo.rumbleon.com/page/user-agreement"
                  >
                    Terms and Conditions
                  </a>
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 0 }}>
                <Button type="link">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://odoo.rumbleon.com/page/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </Button>
              </Col>
            </Row>

            <Row>
              <Link to="/about">
                <Button type="link">About</Button>
              </Link>
            </Row>
            <Row>
              <Button
                type="link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://go.rumbleon.com/kickstart-your-career"
              >
                Career
              </Button>
            </Row>
            <Row>
              <Button
                type="link"
                rel="noopener noreferrer"
                href="https://investors.rumbleon.com/"
              >
                Investor Relationships
              </Button>
            </Row>
          </Row>
          <Row css={flexSharedCss}>
            <Button
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/rumbleon.official"
            >
              <Icon type="facebook" />
            </Button>
            <Button
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/rumbleon_"
            >
              <Icon type="twitter" />
            </Button>
            <Button
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/rumbleon.official/"
            >
              <Icon type="instagram" />
            </Button>
            <Button
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCUm6-vHwq6ppsWo5Aj_99tA"
            >
              <Icon type="youtube" />
            </Button>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" css={bottomCss}>
        <p>© 2018 RumbleOn. All rights reserved.</p>
      </Row>
    </Footer>
  );
};

export default AppFooter;
