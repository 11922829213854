import { Reducer } from "redux";
// import { combineReducers } from "redux";

import {
  Submission,
  SET_APPRAISAL_IS_SUBMITTING,
  SET_APPRAISAL_CURRENT_STEP,
  SET_APPRAISAL_COMPLETED_STEPS,
  SET_APPRAISAL_ERROR_MESSAGE,
  RESET_APPRAISAL_ERROR_MESSAGE,
  ADD_APPRAISAL_ATTACHMENTS,
  REMOVE_APPRAISAL_ATTACHMENTS,
  SET_APPRAISAL_UPLOADED_IMAGES,
  ADD_APPRAISAL_SUBMITTED_FORMS,
  SET_APPRAISAL_CURRENT_FORM,
  // RESET_APPRAISAL_STATE_EXCEPT_SUBMITTED_FORMS,
  RESET_APPRAISAL_STATE,
  ADD_CREATED_APPRAISAL,
  ADD_CREATED_APPRAISAL_VINLESS,
  REMOVE_CREATED_APPRAISAL,
  SET_APPRAISAL_CREATE_REQUEST,
  SET_APPRAISAL_UPDATE_REQUEST,
  SET_APPRAISAL_IMAGE_UPLOAD_REQUEST,
  SET_APPRAISAL_DEALER_EMAIL_REQUEST,
  SET_APPRAISAL_ASSIGN_REQUEST,
  SET_APPRAISAL_DEALER_EMAIL,
  SET_ISVINLESS
} from "../type/AppraisalTypes";

import { Vin } from "../type/SharedTypes";

import { removePropFromObj } from "../util";

type FormFields = {
  ItemYear: number;
  Make: string;
  Model: string;
  Trim?: string;
  ZipCode: string;
  Mileage: number;
  ItemOptions?: {
    IsInstalled: boolean;
    IsStandard: boolean;
    OptionName: string;
  }[];
  Color: string;
  ExteriorColor?: string;
  InteriorColor?: string;
  CustomerNotes?: string;
  DoesYourCarRun?: boolean;
  HasBeenOperated?: boolean;
  HasKeys?: boolean;
  HasDamage: boolean;
  IsTrade: boolean;
  DamageDescription?: string;
  RunDescription?: string;
  HasBeenSmokedIn?: boolean;
  OverallConditionId?: number;
  MechanicalConditionId?: number;
  PhysicalConditionId?: number;
  TireConditionId?: number;
  Attachments?: { AttachmentTypeId: 17 | 3020 }[];
};

type ExtraRequiredFields = {
  Vin: string;
};

type ExtraFields = {
  Decoder: string;
  CategoryName: string;
  Id: string;
  MarketingCategory: "car" | "motorcycle" | "boat";
};

type ToBeSubmitted = {
  ItemTypeId: number;
  Bike?: {};
  Car?: {};
};

type AppraisalState = {
  currentStep: number;
  completedSteps: number[];
  dealerEmail: string | null;
  attachments: object[];
  isSubmitting: boolean;
  createRequest: Submission;
  updateRequest: Submission;
  dealerEmailRequest: Submission;
  imageUploadRequest: Submission;
  assignRequest: Submission;
  errorMessage: string;
  currentForm: object;
  submittedForms: { [key: string]: string };
  createdAppraisals: { [key: string]: string };
  uploadedImages: any[];
  Vinless?: any;
};

const initialState: AppraisalState = {
  currentStep: 1,
  completedSteps: [],
  dealerEmail: null,
  createdAppraisals: {},
  currentForm: {},
  attachments: [],
  submittedForms: {},
  uploadedImages: [],
  isSubmitting: false,
  createRequest: "pending",
  updateRequest: "pending",
  imageUploadRequest: "pending",
  dealerEmailRequest: "pending",
  assignRequest: "pending",
  errorMessage: "",
  Vinless: null
};

const AppraisalReducer: Reducer = (
  state: AppraisalState = initialState,
  action: any
) => {
  const { type, payload } = action;

  // console.log('%c AppraisalReducer', 'color: red',
  //   action,
  //   payload,
  //   state)

  switch (type) {
    case SET_APPRAISAL_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: payload,
      };
    case SET_ISVINLESS:
      return {
        ...state,
        Vinless: payload,
      };
    case SET_APPRAISAL_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case SET_APPRAISAL_COMPLETED_STEPS:
      return {
        ...state,
        completedSteps: payload,
      };
    case SET_APPRAISAL_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: payload,
      };
    case RESET_APPRAISAL_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      };
    case ADD_APPRAISAL_ATTACHMENTS:
      return {
        ...state,
        attachments: [...state.attachments, payload],
      };
    case REMOVE_APPRAISAL_ATTACHMENTS:
      return {
        ...state,
        attachments: state.attachments.filter(
          (i: any) => i.uid !== payload.uid
        ),
      };
    case SET_APPRAISAL_CURRENT_FORM:
      return {
        ...state,
        currentForm: payload,
      };

    case ADD_APPRAISAL_SUBMITTED_FORMS:
      return {
        ...state,
        submittedForms: { ...state.submittedForms, payload }, //@todo: for later
      };

    case RESET_APPRAISAL_STATE:
      return {
        ...initialState,
        submittedForms: state.submittedForms,
        createdAppraisals: state.createdAppraisals,
      };

    case ADD_CREATED_APPRAISAL:
      return {
        ...state,
        createdAppraisals: {
          ...state.createdAppraisals,
          [payload.vin]: payload.appraisalRefNo,
        },
      };
    case ADD_CREATED_APPRAISAL_VINLESS:
      return {
        ...state,
        createdAppraisals: {
          ...state.createdAppraisals,
          AppraisalRefNo: payload.appraisalRefNo,
        },
      };

    case REMOVE_CREATED_APPRAISAL:
      return {
        ...state,
        createdAppraisals: removePropFromObj(state.createdAppraisals, payload),
      };

    case SET_APPRAISAL_CREATE_REQUEST:
      return {
        ...state,
        createRequest: payload,
      };

    case SET_APPRAISAL_UPDATE_REQUEST:
      return {
        ...state,
        updateRequest: payload,
      };

    case SET_APPRAISAL_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        imageUploadRequest: payload,
      };

    case SET_APPRAISAL_DEALER_EMAIL_REQUEST:
      return {
        ...state,
        dealerEmailRequest: payload,
      };

    case SET_APPRAISAL_ASSIGN_REQUEST:
      return {
        ...state,
        assignRequest: payload,
      };

    case SET_APPRAISAL_UPLOADED_IMAGES:
      return {
        ...state,
        uploadedImages: payload,
      };

    case SET_APPRAISAL_DEALER_EMAIL:
      return {
        ...state,
        dealerEmail: payload,
      };

    default:
      return state;
  }
};

export default AppraisalReducer;
