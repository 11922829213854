import React, { FC } from "react";
import { css } from "@emotion/core";

import {
  Row,
  Col,
} from "antd";

export const CenterLayout: FC<any> = ({ children, emCss, ...props }) => (

  <Row
    type="flex"
    gutter={16}
    {...props}
    css={emCss}
  >
    <Col
      xs={{ span: 24 }}
      sm={{ span: 22, offset: 1 }}
      md={{ span: 18, offset: 3 }}
      lg={{ span: 16, offset: 4 }}
      xl={{ span: 14, offset: 5 }}
      xxl={{ span: 12, offset: 6 }}
    >
      {children}
    </Col>
  </Row>
)

export const GuestPageLayout: FC<any> = ({ children, emCss, ...props }) => (

  <Row
    type="flex"
    gutter={16}
    {...props}
    css={emCss}
  >
    <Col
      xs={{ span: 24 }}
      sm={{ span: 22, offset: 1 }}
      md={{ span: 18, offset: 3 }}
      lg={{ span: 18, offset: 3 }}
      xl={{ span: 20, offset: 2 }}
      xxl={{ span: 20, offset: 2 }}
    >
      {children}
    </Col>
  </Row>
)