import React, { Suspense, lazy } from "react";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { css } from "@emotion/core";
import { Spin, Icon } from "antd";
import { store, persistor } from "./reducer";
import { PersistGate } from "redux-persist/integration/react";
import AppLayoutContainer from "./container/AppLayoutContainer";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";
import SystemState from "./type";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AsyncAccountPage = lazy(() => import("./page/AccountPage"));
const AsyncUsersPage = lazy(() => import("./page/UsersPage"));
const AsyncPrivacyPolicyPage = lazy(() => import("./page/PrivacyPrivacyPage"));
const AsyncLeadPage = lazy(() => import("./page/LeadPage"));
const AsyncHelpPage = lazy(() => import("./page/HelpPage"));
const AsyncInventoryPage = lazy(() => import("./page/InventoryPage"));
const AsyncHomePage = lazy(() => import("./container/HomePageContainer"));
const AsyncLoginPage = lazy(() => import("./page/LoginPage"));
const AsyncSignupPage = lazy(() => import("./page/SignUpPage"));
const AsyncForgetPasswordPage = lazy(() => import("./page/ForgetPasswordPage"));
const AsyncResetPasswordPage = lazy(() => import("./page/ResetPasswordPage"));
const AsyncSellPage = lazy(() => import("./page/SellPage"));
const AsyncCarOfferPage = lazy(() => import("./page/CreateCarCashOfferPage"));
const AsyncBoatOfferPage = lazy(() => import("./page/CreateBoatCashOfferPage"));
const AsyncCycleProSearchPage = lazy(() => import("./page/CycleProSearchPage"));
const AsyncMotorcycleOfferPage = lazy(() =>
  import("./page/CreateMotorcycleOfferPage")
);
const AsyncAboutUsPage = lazy(() => import("./page/AboutUsPage"));
const AsyncTermsPage = lazy(() => import("./page/TermsPage"));
const AsyncPortalPage = lazy(() => import("./page/PortalPage"));
const AsyncAnalyticsPage = lazy(() => import("./page/AnalyticsPage"));
const AsyncAuthVerify = lazy(() => import("./component/Home/AuthVerify"));
const AsyncNadaDecoder = lazy(() => import("./page/NadaDecoder"));
const AsyncYearAndModel = lazy(() => import("../src/component/NadaDecoder/YearModel/YearandModel"));
const AsyncSelectOptions = lazy(() => import("../src/component/NadaDecoder/SelectOptions/SelectOptions"));


const root: HTMLElement | null = document.getElementById("root");
if (root) {
  root.style.height = "100%";
}

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = props => {
  const isLoggedIn = useSelector((state: SystemState) => state.auth.isLoggedIn);
  return isLoggedIn ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" />
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ScrollToTop>
            <AppLayoutContainer>
              <Suspense
                fallback={
                  <div
                    css={css`
                      text-align: center;
                      padding-top: 35vh;
                      min-height: 75vh;
                      min-width: 80vw;
                    `}
                  >
                    <Spin
                      spinning
                      size="large"
                      indicator={<Icon type="loading-3-quarters" spin />}
                    />
                  </div>
                }
              >
                <Switch>
                  <Route path="/" exact component={AsyncHomePage} />
                  {/* below link is to handle IAS login from old v2 */}
                  <Route path="/auth/sign-in" component={AsyncLoginPage} />
                  <Route
                    path="/auth/reset-password"
                    component={AsyncResetPasswordPage}
                  />

                  <Route path="/login" component={AsyncLoginPage} />
                  <Route path="/signup" component={AsyncSignupPage} />
                  <Route
                    path="/forgetpassword"
                    component={AsyncForgetPasswordPage}
                  />
                  <Route path="/sell" component={AsyncSellPage} />
                  <Route
                    path="/cycleprosearch"
                    component={AsyncCycleProSearchPage}
                  />
                  <Route
                    path="/create-car-offer"
                    component={AsyncCarOfferPage}
                  />
                  <Route
                    path="/create-boat-offer"
                    component={AsyncBoatOfferPage}
                  />
                  <Route
                    path="/create-motorcycle-offer"
                    component={AsyncMotorcycleOfferPage}
                  />
                  <Route path="/about" component={AsyncAboutUsPage} />
                  <Route path="/terms" component={AsyncTermsPage} />
                  <Route
                    path="/others/privacy-policy"
                    component={AsyncPrivacyPolicyPage}
                  />
                  {/* <PrivateRoute
                    path="/analytics"
                    component={AsyncAnalyticsPage}
                  /> */}
                  <PrivateRoute
                    path="/leads/:leadId"
                    component={AsyncLeadPage}
                  />

                  <PrivateRoute path="/users" component={AsyncUsersPage} />

                  <PrivateRoute path="/leads" component={AsyncLeadPage} />

                  <PrivateRoute path="/help" component={AsyncHelpPage} />
                  <PrivateRoute
                    path="/inventory"
                    component={AsyncInventoryPage}
                  />
                  <PrivateRoute path="/account" component={AsyncAccountPage} />
                  {/* <PrivateRoute
                    path="/analytics/dealership"
                    component={AsyncAnalyticsPage}
                  />
                  <PrivateRoute
                    path="/analytics/click-through-rate"
                    component={AsyncAnalyticsPage}
                  />
                  <PrivateRoute
                    path="/analytics/inventory-metric"
                    component={AsyncAnalyticsPage}
                  />
                  <PrivateRoute
                    path="/analytics/advanced-inventory-metric"
                    component={AsyncAnalyticsPage}
                  /> */}
                  <Route
                    path="/portal/boat/:accountId/:redirect?"
                    component={AsyncPortalPage}
                  />
                  <Route
                    path="/portal/:accountId/:redirect?"
                    component={AsyncPortalPage}
                  />
                  <Route
                    path="/nadadecoder"
                    component={AsyncNadaDecoder}
                  />
                  <Route 
                    path="/Select-Year-Model/:makeid/:vehiclecategory/:vehicletype"
                    component={AsyncYearAndModel}
                  />
                  <Route 
                    path="/Select-Options/:categoryid/:makeid/:modelid/:vehicletype"
                    component={AsyncSelectOptions}
                  />
                </Switch>
                <AsyncAuthVerify />
              </Suspense>
            </AppLayoutContainer>
          </ScrollToTop>
        </PersistGate>
      </Provider>
    </Router>
  );
};

export default App;
