export interface UsersState {
  readonly loading: boolean;

  readonly userList: UserItemUpdate[];
  readonly userListError: null | string;

  readonly updateUserSuccess: null | string;
  readonly updateUserError: null | string;

  readonly deleteUserSuccess: null | string;
  readonly deleteUserError: null | string;

  readonly addUserSuccess: null | string;
  readonly addUserError: null | string;

  readonly userTypeList: UserType[];
  readonly userTypeListError: null | string;

  readonly userStatusTypeList: UserStatusType[];
  readonly userStatusTypeListError: null | string;
}

export interface UserItem {
  UserName: string;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  CompanyName: string;
  Password: string;
  PasswordConfirm: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  ZipCode: string;
  Country: string;
  HomePhoneNumber: string;
  OfficePhoneNumber: string;
  MobileNumber: string;
  Email: string;
  UserTypeId: 0;
  AccountId: 0;
  ImageUrl: string;
}

export interface UserItemUpdate extends UserItem {
  UserStatusId: number;
  UserUuid: string;
  UserId: number;
}

export interface UserType {
  "UserTypeId": number,
  "UserType": string
}

export interface UserStatusType {
  "UserStatusId": number,
  "UserStatus": string
}

export const SET_CLEAN_USER_MESSAGES = "SET_CLEAN_USER_MESSAGES";

export const SET_USER_LOADING = "SET_USER_LOADING";

export const SET_GET_USER_LIST_FAILED = "SET_GET_USER_LIST_FAILED";
export const SET_GET_USER_LIST_SUCCESS = "SET_GET_USER_LIST_SUCCESS";

export const SET_UPDATE_USER_FAILED = "SET_UPDATE_USER_FAILED";
export const SET_UPDATE_USER_SUCCESS = "SET_UPDATE_USER_SUCCESS";

export const SET_ADD_USER_FAILED = "SET_ADD_USER_FAILED";
export const SET_ADD_USER_SUCCESS = "SET_ADD_USER_SUCCESS";

export const SET_DELETE_USER_FAILED = "SET_DELETE_USER_FAILED";
export const SET_DELETE_USER_SUCCESS = "SET_DELETE_USER_SUCCESS";

export const SET_GET_USER_TYPE_LIST_FAILED = "SET_GET_USER_TYPE_LIST_FAILED";
export const SET_GET_USER_TYPE_LIST_SUCCESS = "SET_GET_USER_TYPE_LIST_SUCCESS";

export const SET_GET_USER_STATUS_LIST_FAILED = "SET_GET_USER_STATUS_LIST_FAILED";
export const SET_GET_USER_STATUS_LIST_SUCCESS = "SET_GET_USER_STATUS_LIST_SUCCESS";

interface SetUserLoading {
  type: typeof SET_USER_LOADING;
  payload: boolean;
}

interface SetClearUserMessages {
  type: typeof SET_CLEAN_USER_MESSAGES;
}

interface SetGetUserTypeListFailed {
  type: typeof SET_GET_USER_TYPE_LIST_FAILED;
  payload: string | null;
}

interface SetGetUserTypeListSuccess {
  type: typeof SET_GET_USER_TYPE_LIST_SUCCESS;
  payload: UserType[];
}

interface SetGetUserStatusListFailed {
  type: typeof SET_GET_USER_STATUS_LIST_FAILED;
  payload: string | null;
}

interface SetGetUserStatusListSuccess {
  type: typeof SET_GET_USER_STATUS_LIST_SUCCESS;
  payload: UserStatusType[];
}


interface SetGetUserListFailed {
  type: typeof SET_GET_USER_LIST_FAILED;
  payload: string | null;
}

interface SetGetUserListSuccess {
  type: typeof SET_GET_USER_LIST_SUCCESS;
  payload: UserItemUpdate[];
}

interface SetUpdateUserFailed {
  type: typeof SET_UPDATE_USER_FAILED;
  payload: string | null;
}

interface SetUpdateUserSuccess {
  type: typeof SET_UPDATE_USER_SUCCESS;
  payload: string | null;
}

interface SetDeleteUserFailed {
  type: typeof SET_DELETE_USER_FAILED;
  payload: string | null;
}

interface SetAddUserFailed {
  type: typeof SET_ADD_USER_FAILED;
  payload: string | null;
}

interface SetAddUserSuccess {
  type: typeof SET_ADD_USER_SUCCESS;
  payload: string | null;
}

interface SetDeleteUserSuccess {
  type: typeof SET_DELETE_USER_SUCCESS;
  payload: string | null;
}

export type UsersAction =
  | SetGetUserTypeListFailed
  | SetGetUserTypeListSuccess
  | SetGetUserStatusListFailed
  | SetGetUserStatusListSuccess
  | SetUserLoading
  | SetClearUserMessages
  | SetGetUserListFailed
  | SetGetUserListSuccess
  | SetUpdateUserFailed
  | SetUpdateUserSuccess
  | SetAddUserFailed
  | SetAddUserSuccess
  | SetDeleteUserFailed
  | SetDeleteUserSuccess;
