import SystemState from "../type";
import { connect } from "react-redux";
import {
  setAuthenticationLogOut,
  setSelectedDealer
} from "../action/AuthenticationAction";
import { resetIasMarketError } from "../action/IasMarketAction";
import AppLayout from "../component/AppLayout/AppLayout";
import { iasMarketLoginThunkAction } from "../thunk/IasMarketThunk";
import { setSelectedDealerForAnalytics } from "../action/DealershipAnalyticAction";
const mapStateToProps = (state: SystemState) => ({
  dealerList: state.auth.dealers,
  isAuthorized: state.auth.isLoggedIn,
  userDealerList: state.auth.dealers,
  isIasMarketLogginIn: state.iasMarket.isLoggingIn,
  isIasMarketLoginFailed: state.iasMarket.isFailed
});

const mapDispatchToProps = {
  logout: setAuthenticationLogOut,
  resetIasError: resetIasMarketError,
  handleIasLogin: iasMarketLoginThunkAction,
  handleSelectDealer: setSelectedDealer,
  handleSelectDealerForAnalytics: setSelectedDealerForAnalytics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLayout);
