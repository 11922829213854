import React, { FC, useState, Fragment } from "react";

import { Row, Col, Popover } from 'antd';
import { EmButton } from './EmButton'

export const EmPopover: FC<any> = ({ bttnColor, onConfirm, okText, cancelText, placement, title, children }) => {

  const [visible, setVisible] = useState(false);

  const handleYes = (e: any) => {
    onConfirm(e);
    setVisible(false)
  }

  return (
    <Popover
      content={
        <Fragment>
          <Row><p>{title}</p></Row>
          <Row>
            <Col span={11}>
              <EmButton size="small" bttnColor={bttnColor} onClick={() => setVisible(false)}>{cancelText}</EmButton>
            </Col>
            <Col span={11} offset={2}>
              <EmButton size="small" type="primary" bttnColor={bttnColor} onClick={handleYes}>{okText}</EmButton>
            </Col>
          </Row>
        </Fragment>
      }
      trigger="click"
      visible={visible}
      placement={placement}
      onVisibleChange={() => setVisible(!visible)}
    >
      {children}
    </Popover>
  )
}