export interface InventoryState {
  readonly loadingInventory: boolean;
  readonly inventoryErrorMessage: string | null;
  readonly inventories: InventoryItemResponse[];

  readonly updateInventorySuccessMessage: string | null;
  readonly updateInventoryErrorMessage: string | null;
  readonly updateInventoryLoading: boolean;

  readonly deleteVehicleErrorMessage: string | null;
  readonly deleteVehicleLoading: boolean;
  readonly addInventoryLoading: boolean;
  readonly addInventoryStatus: boolean;

  readonly editedImageUploading: boolean;
  readonly editedImage: any;
  readonly editedImageUploadError: string | null;
}

export interface InventoryItemResponse {
  Vin: string;
  Make: string;
  Model: string;
  ItemYear: number;
  ItemName: string;
  Color: null | string;
  StockNo: string;
  Description: string;
  StockTypeId: null | string;
  MSRP: number;
  DealerPrice: number;
  Miles: number;
  ImagesUrl: InventoryImage[];
  DealerItemId: number;
  AccountId: number;
  ParentId: null | string;
  ItemStatusId: 1;
  BodyStyle: null | string;
  InteriorColor: null | string;
  Transmission: null | string;
  Engine: null | string;
  ItemTypeId: null | string;
  Trim: string;
  Category: null | string;
  VideoURL: string[];
  ListingId: string;
  ImportDate: string;
  ImportType: null;
  ListedDate: string;
  PushedToDD: boolean;
  PushedToDDOn: string;
  DealerVdpUrl: null | string;
  WholesalePrice: null | number;
  BuyNowPrice: null | number;
  CurrentBid: null | number;
  ReservePrice: null | number;
  SellerDisclosure: null | string;
}

export interface InventoryImage {
  MediaId: number;
  Rank: number;
  Url: string;
}

export const SET_GET_INVENTORY_LOADING = "SET_GET_INVENTORY_LOADING";
export const SET_GET_INVENTORY_FAILED = "SET_GET_INVENTORY_FAILED";
export const SET_GET_INVENTORY_SUCCESS = "SET_GET_INVENTORY_SUCCESS";

export const SET_UPDATE_INVENTORY_LOADING = "SET_UPDATE_INVENTORY_LOADING";
export const SET_UPDATE_INVENTORY_FAILED = "SET_UPDATE_INVENTORY_FAILED";
export const SET_UPDATE_INVENTORY_SUCCESS = "SET_UPDATE_INVENTORY_SUCCESS";

export const SET_VEHICLE_DELETE_LOADING = "SET_VEHICLE_DELETE_LOADING";
export const SET_VEHICLE_DELETE_FAILED = "SET_VEHICLE_DELETE_FAILED";

export const SET_ADD_INVENTORY_LOADING = "SET_ADD_INVENTORY_LOADING";
export const SET_ADD_INVENTORY_SUCCESS = "SET_ADD_INVENTORY_SUCCESS";
export const SET_ADD_INVENTORY_FAILED = "SET_ADD_INVENTORY_FAILED";
export const SET_CLEAR_INVENTORY_MESSAGES = "SET_CLEAR_INVENTORY_MESSAGES";

export const SET_INVENTORY_EDITED_IMG_UPLOADING =
  "SET_INVENTORY_EDITED_IMG_UPLOADING";
export const SET_INVENTORY_EDITED_IMG_UPLOAD_SUCCESS =
  "SET_INVENTORY_EDITED_IMG_UPLOAD_SUCCESS";
export const SET_INVENTORY_EDITED_IMG_UPLOAD_FAILED =
  "SET_INVENTORY_EDITED_IMG_UPLOAD_FAILED";

interface SetDeleteVehicleLoading {
  type: typeof SET_VEHICLE_DELETE_LOADING;
  payload: boolean;
}

interface SetDeleteVehicleFailed {
  type: typeof SET_VEHICLE_DELETE_FAILED;
  payload: string | null;
}

interface SetGetInventoryLoading {
  type: typeof SET_GET_INVENTORY_LOADING;
  payload: boolean;
}

interface SetGetInventorySuccess {
  type: typeof SET_GET_INVENTORY_SUCCESS;
  payload: InventoryItemResponse[];
}

interface SetGetInventoryFailed {
  type: typeof SET_GET_INVENTORY_FAILED;
  payload: string | null;
}

interface SetUpdateInventoryLoading {
  type: typeof SET_UPDATE_INVENTORY_LOADING;
  payload: boolean;
}

interface SetUpdateInventoryFailed {
  type: typeof SET_UPDATE_INVENTORY_FAILED;
  payload: string | null;
}

interface SetUpdateInventorySuccess {
  type: typeof SET_UPDATE_INVENTORY_SUCCESS;
  payload: string;
}

interface SetAddInventoryLoading {
  type: typeof SET_ADD_INVENTORY_LOADING;
  payload: boolean;
}

interface SetAddInventorySuccess {
  type: typeof SET_ADD_INVENTORY_SUCCESS;
  payload: boolean;
}

interface SetAddInventoryFailed {
  type: typeof SET_ADD_INVENTORY_FAILED;
  payload: boolean;
}
interface SetClearInventoryMessages {
  type: typeof SET_CLEAR_INVENTORY_MESSAGES;
}

interface SetInventoryEditedImageUploading {
  type: typeof SET_INVENTORY_EDITED_IMG_UPLOADING;
  uploading: boolean;
}

interface SetCashOfferEditedImageUploadSuccess {
  type: typeof SET_INVENTORY_EDITED_IMG_UPLOAD_SUCCESS;
  updatedFile: any;
}

interface SetCashOfferEditedImageUploadFailed {
  type: typeof SET_INVENTORY_EDITED_IMG_UPLOAD_FAILED;
  errorMessage: string;
}

export type InventoryAction =
  | SetDeleteVehicleLoading
  | SetDeleteVehicleFailed
  | SetUpdateInventoryLoading
  | SetUpdateInventoryFailed
  | SetAddInventoryLoading
  | SetAddInventorySuccess
  | SetAddInventoryFailed
  | SetGetInventoryLoading
  | SetGetInventorySuccess
  | SetGetInventoryFailed
  | SetClearInventoryMessages
  | SetUpdateInventorySuccess
  | SetInventoryEditedImageUploading
  | SetCashOfferEditedImageUploadSuccess
  | SetCashOfferEditedImageUploadFailed;
