import {
  UsersState,
  UsersAction,
  SET_CLEAN_USER_MESSAGES,
  SET_USER_LOADING,
  SET_GET_USER_LIST_FAILED,
  SET_GET_USER_LIST_SUCCESS,
  SET_UPDATE_USER_FAILED,
  SET_UPDATE_USER_SUCCESS,
  SET_DELETE_USER_FAILED,
  SET_DELETE_USER_SUCCESS,
  SET_ADD_USER_FAILED,
  SET_ADD_USER_SUCCESS,
  SET_GET_USER_TYPE_LIST_FAILED,
  SET_GET_USER_TYPE_LIST_SUCCESS,
  SET_GET_USER_STATUS_LIST_FAILED,
  SET_GET_USER_STATUS_LIST_SUCCESS,
} from "../type/UsersType";

const initialState: UsersState = {
  loading: false,

  userList: [],
  userListError: null,

  updateUserSuccess: null,
  updateUserError: null,

  deleteUserSuccess: null,
  deleteUserError: null,

  addUserSuccess: null,
  addUserError: null,

  userTypeList: [],
  userTypeListError: null,

  userStatusTypeList: [],
  userStatusTypeListError: null,
};

const usersReducer = (
  state = initialState,
  action: UsersAction
): UsersState => {
  switch (action.type) {
    case SET_CLEAN_USER_MESSAGES:
      return {
        ...state,

        userListError: null,
        updateUserSuccess: null,
        updateUserError: null,
        deleteUserSuccess: null,
        deleteUserError: null,
        addUserSuccess: null,
        addUserError: null,
      };

    case SET_USER_LOADING:
      return { ...state, loading: action.payload };

    case SET_GET_USER_LIST_FAILED:
      return { ...state, userListError: action.payload };
    case SET_GET_USER_LIST_SUCCESS:
      return { ...state, userList: action.payload };

    case SET_UPDATE_USER_FAILED:
      return { ...state, updateUserError: action.payload };
    case SET_UPDATE_USER_SUCCESS:
      return { ...state, updateUserSuccess: action.payload };

    case SET_DELETE_USER_FAILED:
      return { ...state, deleteUserError: action.payload };
    case SET_DELETE_USER_SUCCESS:
      return { ...state, deleteUserSuccess: action.payload };

    case SET_ADD_USER_FAILED:
      return { ...state, addUserError: action.payload };
    case SET_ADD_USER_SUCCESS:
      return { ...state, addUserSuccess: action.payload };


    case SET_GET_USER_TYPE_LIST_FAILED:
      return { ...state, userTypeListError: action.payload };
    case SET_GET_USER_TYPE_LIST_SUCCESS:
      return { ...state, userTypeList: action.payload || [] };

    case SET_GET_USER_STATUS_LIST_FAILED:
      return { ...state, userStatusTypeListError: action.payload };
    case SET_GET_USER_STATUS_LIST_SUCCESS:
      return { ...state, userStatusTypeList: action.payload };

    default:
      return state;
  }
};

export default usersReducer;
