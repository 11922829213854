import {
  InventoryMetricState,
  InventoryMetricAction,
  SET_INVENTORY_METRIC_LOADING,
  SET_INVENTORY_METRIC_FAILED,
  SET_GET_INVENTORY_METRIC_SUCCESS,
  SET_GET_INVENTORY_CLICK_SUCCESS,
  SET_CONDITIONAL_IMPRESSIONS_SUCCESS,
  SET_CONDITIONAL_CLICKS_SUCCESS,
} from "../type/InventoryMetricType";

const initialState: InventoryMetricState = {
  isLoading: false,
  errorMessage: null,
  impressionsVsInventories: [],
  clicksVsInventories: [],
  conditionImpression: {
    xLabels: [],
    dataLabels: [],
    data: [],
    download: [],
  },
  conditionClick: {
    xLabels: [],
    dataLabels: [],
    data: [],
    download: [],
  },
};

const InventoryMetricReducer = (
  state: InventoryMetricState = initialState,
  action: InventoryMetricAction
) => {
  switch (action.type) {
    case SET_INVENTORY_METRIC_LOADING:
      return { ...state, isLoading: false };
    case SET_INVENTORY_METRIC_FAILED:
      return { ...state, errorMessage: false };
    case SET_GET_INVENTORY_METRIC_SUCCESS:
      return { ...state, impressionsVsInventories: action.payload };
    case SET_GET_INVENTORY_CLICK_SUCCESS:
      return { ...state, clicksVsInventories: action.payload };
    case SET_CONDITIONAL_IMPRESSIONS_SUCCESS:
      return { ...state, conditionImpression: action.payload };
    case SET_CONDITIONAL_CLICKS_SUCCESS:
      return { ...state, conditionClick: action.payload };
    default:
      return state;
  }
};

export default InventoryMetricReducer;
