// We are using jsx here because typescript's issue with higher order function
import { combineReducers } from "redux";
import CreateCashOfferReducer, {
  CreateCashOfferByTypeReducer
} from "./CreateCashOfferByTypeReducer";

const combinedCreateCashOfferReducer = combineReducers({
  car: CreateCashOfferReducer(CreateCashOfferByTypeReducer, "car"),
    motorcycle: CreateCashOfferReducer(CreateCashOfferByTypeReducer, "motorcycle"),
  boat: CreateCashOfferReducer(CreateCashOfferByTypeReducer, "boat")
});

export default combinedCreateCashOfferReducer;
