import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import SystemState from "../type";
import {
  resetAuthenticationErrorMessage,
  setAuthenticaionLoading,
  setAuthenticationFailed,
  setAuthenticationSuccess,
  setDealerDetailList,
  setForgetPasswordEmailValidationSuccess,
  setForgetPasswordEmailValidationError,
  setSelectedDealer,
  SetIsInternalDealer,
  setElasticInfo,
  setResetPasswordFailed,
  setResetPasswordSuccess,
  setAccountFeatures,
} from "../action/AuthenticationAction";
import moment from 'moment'
import { environmentVariableList } from "../constant";
import { message } from "antd";

const env = environmentVariableList();

export const forgetPasswordEmailValidationThunkAction = (
  email: string
): ThunkAction<void, SystemState, null, Action> => async dispatch => {
  dispatch(setAuthenticaionLoading(true));
  dispatch(setForgetPasswordEmailValidationError(""));
  try {
    const endPoint = new URL(
      `${env.REACT_APP_AUTHENTICATION_END_POINT}/api/Authentication/ForgotPassword`
    );
    const queryParams = new URLSearchParams({
      email: email,
      site: "Dealer",
    });
    endPoint.search = queryParams.toString();
    const response = await fetch(endPoint.toString());
    if (response.ok) {
      // handle Success
      dispatch(setForgetPasswordEmailValidationError(""));
      dispatch(setForgetPasswordEmailValidationSuccess(true));
    } else {
      throw new Error("Invalid Email");
    }
  } catch (error) {
    console.error("Email validation failed: ", error);
    dispatch(setForgetPasswordEmailValidationSuccess(false));
    dispatch(setForgetPasswordEmailValidationError("Invalid Email"));
  }
  dispatch(setAuthenticaionLoading(false));
};

export const loginThunkAction = (
  email: string,
  password: string
): ThunkAction<void, SystemState, null, Action> => async dispatch => {
  dispatch(resetAuthenticationErrorMessage());
  dispatch(setAuthenticaionLoading(true));
  try {
    const response = await fetch(
      `${env.REACT_APP_AUTHENTICATION_END_POINT}/api/authentication/login`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ClientId: "rumbleon-consumer",
          Username: email,
          Password: password,
          GetJWTToken: true,
        }),
      }
    );
    const json = await response.json();
    if (response.ok) {
      window.localStorage.setItem('auth', JSON.stringify(json));
      window.localStorage.setItem('expiration', moment(Date.now()).add(json.TokenValidPeriod, 'second').format("L LTS"));
      dispatch(setAuthenticationSuccess(json));
      dispatch(setSelectedDealer(undefined));
    } else {
      dispatch(setAuthenticationFailed(json));
    }
  } catch (error) {
    console.error("Log in failed with error:", error);
  }
  dispatch(setAuthenticaionLoading(false));
};

export const resetPasswordThunkAction = (
  password: string,
  code: string,
  userUuid: string
): ThunkAction<void, SystemState, null, Action> => async dispatch => {
  dispatch(resetAuthenticationErrorMessage());
  dispatch(setAuthenticaionLoading(true));
  const fixUrlIssue = code.split(" ").join("+");
  try {
    const response = await fetch(
      `${env.REACT_APP_AUTHENTICATION_END_POINT}/api/Authentication/ResetPassword`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserUuid: userUuid,
          Password: password,
          ConfirmPassword: password,
          Code: fixUrlIssue,
        }),
      }
    );
    const json = await response.json();
    if (response.ok && json.Success) {
      dispatch(setResetPasswordSuccess(json.Message));
    } else {
      dispatch(setResetPasswordFailed(json.ErrorDetails || "Failed to reset"));
    }
  } catch (error) {
    console.error("Log in failed with error:", error);
    dispatch(setResetPasswordFailed("Something went wrong"));
  }
  dispatch(setAuthenticaionLoading(false));
};

export const refreshAuthThunkAction = (): ThunkAction<
  void,
  SystemState,
  null,
  Action
> => async (dispatch, getState) => {
  try {
    const state = getState();
    const userUuid = state.auth.userUuid;
    dispatch(setAuthenticaionLoading(true));
    const esCredResp = await fetch(
      `${env.REACT_APP_AUTHENTICATION_END_POINT}/api/authentication/GetDealerSearchConfig`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Uuid: userUuid,
        }),
      }
    );
    const esCredRespJson = await esCredResp.json();
    dispatch(setElasticInfo(window.atob(esCredRespJson)));
    // dispatch(setAuthenticaionLoading(false));
    // fetch related dealer list after success
    const dealerResponse = await fetch(
      `${env.REACT_APP_DEALER_LIST_END_POINT}/Services/API/User/GetUserDealerDetailsList`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserUuid: userUuid,
        }),
      }
    );
    const dealerJson = await dealerResponse.json();
    dispatch(setDealerDetailList(dealerJson));

    // Set default selected dealer
    if (dealerJson && dealerJson[0] && dealerJson[0].AccountId) {
      dispatch(setSelectedDealer(dealerJson[0].AccountId));
    }
    if (dealerJson && dealerJson.length > 0) {
      for (var i = 0; i < dealerJson.length; i++) {
        if (dealerJson[i].IsInternalDealer === true) {
          dispatch(SetIsInternalDealer(true));
          break;
        }
      }

    }
    dispatch(setAuthenticaionLoading(false));
  } catch (error) {
    dispatch(setAuthenticaionLoading(false));
    console.error("Failed to get fresh user into", error);
  }
};

export const getAccountFeaturesThunk = (): ThunkAction<
  void,
  SystemState,
  null,
  Action
> => async (dispatch, getState) => {
  try {
    const state = getState();
    const { userUuid } = state.auth;
    const response = await fetch(
      `${env.REACT_APP_INVENTORY_END_POINT}/Services/API/DealerInventory/GetAccountFeatures`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AccountId: 208,
          UserUuid: userUuid,
        }),
      }
    );
    const result = await response.json();
    if (result && result.AccountFeatures) {
      dispatch(setAccountFeatures(result.AccountFeatures));
    } else {
      dispatch(setAccountFeatures([]));
    }
  } catch (error) {
    dispatch(setAccountFeatures([]));
  }
};
