import {
  DelearDetailResult,
  DealerParentChild,
} from "../type/AuthenticationType";
import { store } from "../reducer";
import { setSelectedDealerForAnalytics } from "../action/DealershipAnalyticAction";

interface ParentType {
  [key: string]: {
    AccountName: string;
    AccountId: number;
    children: DelearDetailResult[];
  };
}

export function getSelectedOrDefaultDealerAccounts(): number[] {
  const state = store.getState();

  const { selectedDealerAccounts } = state.dealershipAnalytic;
  const { dealers } = state.auth;

  let accountIds = selectedDealerAccounts;
  if (selectedDealerAccounts.length === 0) {
    const parentAccounts = getParentChildDealerList(dealers);
    if (parentAccounts[0]) {
      accountIds = parentAccounts[0].childrenIds;
      store.dispatch(setSelectedDealerForAnalytics(accountIds));
    }
  }
  return accountIds;
}
export function getParentChildDealerList(
  accounts: DelearDetailResult[]
): DealerParentChild[] {
  const parents: ParentType = {};
  const parentsChildren: DealerParentChild[] = [];

  accounts.forEach(item => {
    if (item.ParentAccountId === null) {
      let children: DelearDetailResult[] = [item];
      if (parents[item.AccountId]) {
        children = [...children, ...parents[item.AccountId].children];
      }

      parents[`${item.AccountId}`] = {
        AccountName: item.AccountName,
        AccountId: item.AccountId,
        children,
      };
    } else {
      if (parents[item.ParentAccountId]) {
        parents[item.ParentAccountId].children.push(item);
      } else {
        if (item.ParentAccountName && item.ParentAccountId) {
          parents[item.ParentAccountId] = {
            AccountName: item.ParentAccountName,
            AccountId: item.ParentAccountId,
            children: [item],
          };
        }
      }
    }
  });

  for (const [key, value] of Object.entries(parents)) {
    const newParent = {
      ...value,
      childrenIds: value.children.map(item => item.AccountId),
    };
    parentsChildren.push(newParent);
  }

  return parentsChildren;
}
