import {
  AuthenticationState,
  SET_AUTHENTICATION_LOADING,
  SET_AUTHENTICATION_FAILED,
  RESET_AUTHENTICATION_ERROR_MESSAGE,
  SET_AUTHENTICATION_SUCCESS,
  SET_DEALER_DETAILS_LIST,
  SET_AUTHENTICATION_LOG_OUT,
  SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS,
  SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE,
  RESET_FORGET_PASSWORD_STATUS,
  SET_SELECTED_DEALER,
  SET_ELASTIC_INFO,
  SET_RESET_PASSWORD_FAILED,
  SET_RESET_PASSWORD_SUCCESS,
  SET_IS_INTERNAL_DEALER,
  AuthenticationActionTypes,
  SET_ACCOUNT_FEATURES,
} from "../type/AuthenticationType";

const initialState: AuthenticationState = {
  isLoading: false,
  isLoggedIn: false,
  esCred: "",
  forgetPasswordEmailValidationSuccess: false,
  forgetPasswordEmailValidationError: "",
  dealers: [],
  resetPasswordSuccess: null,
  resetPasswordError: null,
  accountFeatures: [],
  IsInternalDealer: false
};

const authenticationReducer = (
  state = initialState,
  action: AuthenticationActionTypes
): AuthenticationState => {
  switch (action.type) {
    case SET_AUTHENTICATION_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_RESET_PASSWORD_FAILED:
      return { ...state, resetPasswordError: action.payload };
    case SET_RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: action.payload };
    case SET_IS_INTERNAL_DEALER:
      return { ...state, IsInternalDealer: action.payload };
    case SET_AUTHENTICATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: action.payload.Success,
        failedErrorMessage: action.payload.Message,
      };
    case RESET_AUTHENTICATION_ERROR_MESSAGE:
      return {
        ...state,
        resetPasswordSuccess: null,
        resetPasswordError: null,
        failedErrorMessage: undefined,
      };
    case SET_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        failedErrorMessage: undefined,
        userUuid: action.payload.Uuid,
        userId: action.payload.UserId,
        userTypeId: action.payload.UserTypeId,
        sessionToken: action.payload.Token,
        dealerJWTToken: action.payload.DealerJWTToken,
        userEmail: action.payload.Email,
        userFirstname: action.payload.FirstName,
        userLastName: action.payload.LastName,
        city: action.payload.City,
        state: action.payload.State,
        zip: action.payload.Zip,
      };
    case SET_DEALER_DETAILS_LIST:
      return {
        ...state,
        dealers: action.payload,
      };
    case SET_AUTHENTICATION_LOG_OUT:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        failedErrorMessage: undefined,
        userUuid: undefined,
        userId: undefined,
        userTypeId: undefined,
        sessionToken: undefined,
        userEmail: undefined,
        userFirstname: undefined,
        userLastName: undefined,
        dealers: [],
      };
    case SET_FORGET_PASSWORD_EMAIL_VALIDATION_SUCCESS:
      return {
        ...state,
        forgetPasswordEmailValidationSuccess: action.payload,
      };
    case SET_FORGET_PASSWORD_EMAIL_VALIDATION_ERROR_MESSAGE:
      return {
        ...state,
        forgetPasswordEmailValidationError: action.payload,
      };
    case RESET_FORGET_PASSWORD_STATUS:
      return {
        ...state,
        forgetPasswordEmailValidationError: "",
        forgetPasswordEmailValidationSuccess: false,
      };
    case SET_SELECTED_DEALER:
      return {
        ...state,
        selectedDealerAccountId: action.payload,
      };
    case SET_ELASTIC_INFO:
      return {
        ...state,
        esCred: action.payload,
      };
    case SET_ACCOUNT_FEATURES:
      return {
        ...state,
        accountFeatures: action.payload,
      };

    default:
      return state;
  }
};

export default authenticationReducer;
