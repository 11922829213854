import { css } from '@emotion/core'

const breakpoints = [576, 768, 992, 1200, 1600]

export const color = {
  textBlack: '#595a5e',
  textWhite: 'white',
  logoDark: '#2b2b2b',
  borderDark: '#474747',
  bgDark: 'rgba(0, 0, 0, 0.5)',
}

export const minMedia = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

export const maxMedia = breakpoints.map(
  bp => `@media (max-width: ${bp}px)`
)