import { Reducer } from "redux";
import {
  CreateCashOfferPageState,
  CreateCashOfferActionTypes,
  SET_CASH_OFFER_PAGE_LOADING,
  SET_VIN_DECODE_RESULT,
  SET_CREATE_CASH_OFFER_HAS_ERROR,
  RESET_CREATE_CASH_OFFER_ERROR,
  SET_CASH_OFFER_CREATED_SUCCESSFULLY,
  RESET_CASH_OFFER_STATUS,
  VehicleType,
  SET_CASH_OFFER_EDITED_IMG_UPLOADING,
  SET_CASH_OFFER_EDITED_IMG_UPLOAD_SUCCESS,
  SET_CASH_OFFER_EDITED_IMG_UPLOAD_ERROR,
  SET_IMAGES_CHANGED,
} from "../type/CreateCashOfferType";

export const CreateCashOfferByTypeReducer = (
  state: CreateCashOfferPageState,
  action: CreateCashOfferActionTypes,
): CreateCashOfferPageState => {
  switch (action.type) {
    case SET_CASH_OFFER_PAGE_LOADING:
      return {
        ...state,
        isPageLoading: action.payload,
      };
    case SET_IMAGES_CHANGED:
      return {
        ...state,
        imagesChanged: action.payload
      };
    case SET_VIN_DECODE_RESULT:
      return {
        ...state,
        vinDecodeResult: action.payload,
      };
    case SET_CREATE_CASH_OFFER_HAS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case RESET_CREATE_CASH_OFFER_ERROR:
      return {
        ...state,
        errorMessage: "",
      };
    case SET_CASH_OFFER_CREATED_SUCCESSFULLY:
      return {
        ...state,
        isOfferCreatedSuccessfully: true,
      };
    case RESET_CASH_OFFER_STATUS:
      return {
        ...state,
        isPageLoading: false,
        vinDecodeResult: undefined,
        errorMessage: "",
        isOfferCreatedSuccessfully: false,
      };
    case SET_CASH_OFFER_EDITED_IMG_UPLOADING:
      return {
        ...state,
        editedImageUploading: action.uploading,
      };
    case SET_CASH_OFFER_EDITED_IMG_UPLOAD_SUCCESS:
      return {
        ...state,
        editedImage: action.updatedFile,
      };
    case SET_CASH_OFFER_EDITED_IMG_UPLOAD_ERROR:
      return {
        ...state,
        editedImageUploadError: action.errorMessage,
      };
    default:
      return state;
  }
};

const CreateCashOfferReducer = (reducer: Reducer, reducerName: VehicleType) => (
  state: CreateCashOfferPageState,
  action: CreateCashOfferActionTypes,
) => {
  const { vehicleType } = action;
  const isInitialization = state === undefined;
  if (vehicleType !== reducerName && !isInitialization) {
    return state; // fileter reducer by action.vehicleType
  }
  if (isInitialization) {
    const initialState = {
      vehicleType: reducerName,
      isPageLoading: false,
      isOfferCreatedSuccessfully: false,
      errorMessage: "",
      editedImageUploading: false,
      editedImage: null,
      editedImageUploadError: null,
      imagesChanged: false,
    };
    return reducer(initialState, action);
  }
  return reducer(state, action);
};

export default CreateCashOfferReducer;
