import {
  AcceptCashOfferState,
  SET_ACCEPT_OFFER_LOADING_ACTION,
  SET_DEALER_OFFER_DETAIL_ACTION,
  SET_GET_DEALER_OFFER_DETAIL_FAILED_ERROR_MESSAGE_ACTION,
  SET_GET_DEALER_OFFER_DETAIL_SUCCESS_ACTION,
  SET_OFFER_ACCEPT_FAILED_MESSAGE_ACTION,
  SET_OFFER_ACCEPT_SUCCESS_ACTION,
  RESET_ACCEPT_OFFER_STATUS_ACTION,
  AcceptCashOfferActionTypes
} from "../type/AcceptCashOfferType";

const initialState: AcceptCashOfferState = {
  isAcceptOfferLoading: false,
  getDealerOfferDetailFailedErrorMessage: ""
};

const acceptOfferReducer = (
  state = initialState,
  action: AcceptCashOfferActionTypes
): AcceptCashOfferState => {
  switch (action.type) {
    case SET_ACCEPT_OFFER_LOADING_ACTION:
      return {
        ...state,
        isAcceptOfferLoading: action.payload
      };
    case SET_DEALER_OFFER_DETAIL_ACTION:
      return {
        ...state,
        dealerOfferDeail: action.payload
      };
    case SET_GET_DEALER_OFFER_DETAIL_FAILED_ERROR_MESSAGE_ACTION:
      return {
        ...state,
        getDealerOfferDetailFailedErrorMessage: action.payload
      };
    case SET_GET_DEALER_OFFER_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        isGetDealerOfferDetailSuccess: action.payload
      };
    case SET_OFFER_ACCEPT_FAILED_MESSAGE_ACTION:
      return {
        ...state,
        acceptOfferFailedMessage: action.payload
      };
    case SET_OFFER_ACCEPT_SUCCESS_ACTION:
      return {
        ...state,
        isOfferAcceptSuccess: action.payload
      };
    case RESET_ACCEPT_OFFER_STATUS_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default acceptOfferReducer;
