import {
  CTRSummaryData,
  DealershipAnalyticAction,
  DealershipCommonDateCount,
  DealershipCustomerActivityDateCount,
  InventorySummaryData,
  SET_CTR_SUMMARY_FAILED_MESSAGE,
  SET_CTR_SUMMARY_LOADING,
  SET_CTR_SUMMARY_SUCCESS,
  SET_DEALERSHIP_ACCOUNTS_FAILED,
  SET_DEALERSHIP_ACCOUNTS_LOADING,
  SET_DEALERSHIP_ACCOUNTS_SUCCESS,
  SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS,
  SET_DEALERSHIP_DATA_FAILED_MESSAGE,
  SET_DEALERSHIP_DATA_LOADING,
  SET_DEALERSHIP_DEAL_SUCCESS,
  SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS,
  SET_DEALERSHIP_IMPRESSION_SUCCESS,
  SET_INVENTORY_SUMMARY_FAILED_MESSAGE,
  SET_INVENTORY_SUMMARY_LOADING,
  SET_INVENTORY_SUMMARY_SUCCESS,
  SET_SELECTED_DEALERS_FOR_ANALYTICS,
  SET_USER_ACTIVITY_COUNT_SUCCESS,
  UserActivityCount,
  SET_SELECTED_DEALERS_FOR_INVENTORY,
} from "../type/DealerShipAnalyticType";

export const setDealershipAccountsLoading = (
  payload: boolean
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_ACCOUNTS_LOADING,
  payload: payload,
});

export const setDealershipAccountsSuccess = (
  payload: string[]
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_ACCOUNTS_SUCCESS,
  payload: payload,
});

export const setDealershipAccountsFailed = (
  payload: string
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_ACCOUNTS_FAILED,
  payload: payload,
});

export const setDealershipLoading = (
  payload: boolean
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_DATA_LOADING,
  payload: payload,
});

export const setDealershipFailed = (
  payload: string
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_DATA_FAILED_MESSAGE,
  payload,
});

export const setDealershipImpressionSuccess = (
  payload: DealershipCommonDateCount[]
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_IMPRESSION_SUCCESS,
  payload: payload,
});

export const setDealershipDealsSuccess = (
  payload: DealershipCommonDateCount[]
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_DEAL_SUCCESS,
  payload: payload,
});

export const setDealershipDetailPageViewSuccess = (
  payload: DealershipCommonDateCount[]
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_DETAIL_PAGE_VIEW_SUCCESS,
  payload: payload,
});

export const setDealershipCustomerActivitySuccess = (
  payload: DealershipCustomerActivityDateCount
): DealershipAnalyticAction => ({
  type: SET_DEALERSHIP_CUSTOMER_ACTIVITY_SUCCESS,
  payload: payload,
});

export const setCTRSummaryLoading = (
  payload: boolean
): DealershipAnalyticAction => ({
  type: SET_CTR_SUMMARY_LOADING,
  payload: payload,
});

export const setCTRSummaryFailed = (
  payload: string
): DealershipAnalyticAction => ({
  type: SET_CTR_SUMMARY_FAILED_MESSAGE,
  payload,
});

export const setCTRSummarySuccess = (
  payload: CTRSummaryData
): DealershipAnalyticAction => ({
  type: SET_CTR_SUMMARY_SUCCESS,
  payload: payload,
});

export const setInventorySummaryLoading = (
  payload: boolean
): DealershipAnalyticAction => ({
  type: SET_INVENTORY_SUMMARY_LOADING,
  payload: payload,
});

export const setInventorySummaryFailed = (
  payload: string
): DealershipAnalyticAction => ({
  type: SET_INVENTORY_SUMMARY_FAILED_MESSAGE,
  payload,
});

export const setInventorySummarySuccess = (
  payload: InventorySummaryData
): DealershipAnalyticAction => ({
  type: SET_INVENTORY_SUMMARY_SUCCESS,
  payload: payload,
});

export const setDealershipUserActivityCountSuccess = (
  payload: UserActivityCount
): DealershipAnalyticAction => ({
  type: SET_USER_ACTIVITY_COUNT_SUCCESS,
  payload: payload,
});

export const setSelectedDealerForAnalytics = (
  payload: number[]
): DealershipAnalyticAction => ({
  type: SET_SELECTED_DEALERS_FOR_ANALYTICS,
  payload
});

export const setSelectedDealerForInventory = (
  payload: number[]
): DealershipAnalyticAction => ({
  type: SET_SELECTED_DEALERS_FOR_INVENTORY,
  payload
});