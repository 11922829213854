import { 
  nomalizeCapitalCase, 
  checkExpiration,
  findAndSplitArr,
  removePropFromObj,
} from './appraisal'

export {
  nomalizeCapitalCase, 
  checkExpiration,
  findAndSplitArr,
  removePropFromObj,
}

export const formatDate = (date: string) => {
  const esDate = new Date(date);
  return esDate.toLocaleDateString("en-US");
};

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  }).format(value);
};

export const offerStatusMapper = (status: number) => {
  switch (status) {
    case 0:
      return "Inbound";
    case 1:
      return "New";
    case 4:
      return "Active";
    case 5:
      return "Accepted";
    case 6:
      return "Expired";
    case 7:
      return "Terminated";
    case 8:
      return "Purchased";
    case 9:
      return "Nodeal";
    default:
      return "pending";
  }
};

export const offerStatusReverseMapper = (status: string) => {
  switch (status) {
    case "Inbound":
      return 0;
    case "New":
      return 1;
    case "Active":
      return 4;
    case "Accepted":
      return 5;
    case "Expired":
      return 6;
    case "Terminated":
      return 7;
    case "Purchased":
      return 8;
    case "Nodeal":
      return 9;
    default:
      return "";
  }
};
