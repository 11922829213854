export interface ClickThroughRateState {
  readonly isLoading: boolean;
  readonly errorMessage: string | null;

  readonly allTimeCount: AllTimeCount;
  readonly data: CtrData[];
}

export interface AllTimeCount {
  impressions: number | null;
  clicks: number | null;
}

export interface CtrData {
  date: string;
  timestamp: number;
  impressions: number;
  clicks: number;
}

export const SET_CTR_DATA_LOADING = "SET_CTR_DATA_LOADING";
export const SET_CTR_DATA_FAILED = "SET_CTR_DATA_FAILED";

export const SET_CTR_ALL_TIME_COUNT_SUCCESS = "SET_CTR_ALL_TIME_COUNT_SUCCESS";
export const SET_CTR_DATA_SUCCESS = "SET_CTR_DATA_SUCCESS";

interface SetCtrLoadingAction {
  type: typeof SET_CTR_DATA_LOADING;
  payload: boolean;
}

interface SetCtrFailedAction {
  type: typeof SET_CTR_DATA_FAILED;
  payload: string | null;
}

interface SetCtrAllTimeCountSuccess {
  type: typeof SET_CTR_ALL_TIME_COUNT_SUCCESS;
  payload: AllTimeCount;
}

interface SetCtrDataSuccess {
  type: typeof SET_CTR_DATA_SUCCESS;
  payload: CtrData[];
}

export type ClickThroughRateAction =
  | SetCtrLoadingAction
  | SetCtrFailedAction
  | SetCtrAllTimeCountSuccess
  | SetCtrDataSuccess;
