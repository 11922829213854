import React, { FC } from "react";
import { css } from "@emotion/core";

import {
  Button,
} from "antd";

const shared = css`
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
`

const primaryType = (color: string) => css`
  background-color: ${color};
  border-color: ${color};
  &:visited, &:focus, &:active, &:hover {
    background-color: ${color} ;
    border-color: ${color};
  }
  ${shared}
`
const defaultType = (color: string) => css`
  &:visited, &:focus, &:active, &:hover {
    color: ${color};
    border-color: ${color};
  }
  ${shared}
`
export const EmButton: FC<any> = ({ bttnColor, type, children, emCss, ...props }) => {
  return (
    <Button
      css={css`
        ${type === 'primary'? primaryType(bttnColor) : defaultType(bttnColor)}
        ${emCss}
      `}
      type={type}
      {...props}
    >
      {children}
    </Button>
  )
}
