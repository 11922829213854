import {
  AccountDetailsAction,
  SET_ACCOUNT_DETAILS_LOADING,
  AccountDetails,
  SET_ACCOUNT_DETAILS_SUCCESS,
  SET_ACCOUNT_DETAILS_FAILED,
  SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS,
} from "../type/AccountDetailsType";

export const setAccountDetailsLoading = (
  loading: boolean
): AccountDetailsAction => ({
  type: SET_ACCOUNT_DETAILS_LOADING,
  payload: loading,
});

export const setAccountDetailsSuccess = (
  accountDetails: AccountDetails
): AccountDetailsAction => ({
  type: SET_ACCOUNT_DETAILS_SUCCESS,
  payload: accountDetails,
});

export const setAccountDetailsFailed = (
  error: string | null
): AccountDetailsAction => ({
  type: SET_ACCOUNT_DETAILS_FAILED,
  payload: error,
});

export const setSelectedAccountForAccountDetails = (
  accountId: null | number
): AccountDetailsAction => ({
  type: SET_SELECTED_ACCOUNT_FOR_ACCOUNT_DETAILS,
  payload: accountId,
});
