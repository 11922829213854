import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Icon,
  Layout,
  Menu,
  Row,
  Spin,
  notification,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { FC, Fragment, useEffect, useState } from "react";
import { color, minMedia } from "../UiComponents";
import { useDispatch, useSelector } from "react-redux";

import { DelearDetailResult } from "../../type/AuthenticationType";
import { ReactComponent as RumbleLogoDark } from "./asset/rumbleon-logo-dark-grey.svg";
import SystemState from "../../type";
import { css } from "@emotion/core";
import { environmentVariableList } from "../../constant";
import { getParentChildDealerList } from "../../util/account";
import { hasPermission } from "../../util/permission";
import profileLogo from "./asset/default-profile.jpg";
import { setSelectedAccountForAccountDetails } from "../../action/AccountDetailsAction";
import { setSelectedDealerForInventory } from "../../action/DealershipAnalyticAction";

const envList = environmentVariableList();

const Header = Layout.Header;
const { SubMenu, Item } = Menu;

const justifyRight = css`
  display: flex;
  justify-content: flex-end;
`;

const logoCss = css`
  width: 150px;

  ${minMedia[2]} {
    width: 200px;
  }
`;

const fontSizeCss = css`
  font-weight: 600;
  ${minMedia[2]} {
    font-size: 1.1rem;
  }
  ${minMedia[3]} {
    font-size: 1.3rem;
  }
`;

const profileIconCss = css`
  margin-left: 10px;
`;

const menuCss = css`
  border-color: transparent;
  li {
    border-color: transparent !important;
    font-size: 16px;
    font-weight: normal;
  }
`;
const loginCss = css`
  ${menuCss}
  ${fontSizeCss}
  ${justifyRight}
`;

const hMenuCss = css`
  ${menuCss}
  ${fontSizeCss}
  ${minMedia[1]} {
    li {
      padding: 0 0.5rem;
    }
    .ant-menu-submenu-title {
      padding: 0;
    }
  }
  .ant-menu-item{
    padding-left: 10px;
    padding-right: 10px;
  }
  .ant-menu-submenu{
    padding-left: 10px;
    padding-right: 10px;
  }
  ${minMedia[3]} {
    li {
      padding: 0 20px;
    }
    .ant-menu-submenu-title {
      padding: 0;
    }
  }
`;
const subMenuCss = css`
  ${menuCss}
  .ant-menu-submenu-title {
    padding: 0px !important;
  }
`;

const profileCss = css`
  ${minMedia[1]} {
    ${justifyRight}
  }
`;
const DrawerMenuCss = css`
  ${menuCss}
`;

const navIconCss = css`
  padding: 0;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  isAuthorized: boolean;
  userDealerList: any[];
  dealerList: DelearDetailResult[];
  logout: () => void;
  handleSelectDealer: (accountId: number) => void;
  handleSelectDealerForAnalytics: (accountIds: number[]) => void;
  handleIasLogin: () => void;
};

const AppHeader: FC<Props> = ({
  isAuthorized,
  dealerList,
  userDealerList,
  logout,
  handleIasLogin,
  handleSelectDealer,
  handleSelectDealerForAnalytics,
}) => {
  let selectedKeys: string[] = [];
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const toggleMobileMenu = () => setVisible(!visible);

  const shouldDisableDealerMenu = userDealerList && userDealerList.length === 0;
  const shouldHideDealerDropDown = userDealerList && userDealerList.length <= 1;

  return (
    <Fragment>
      <Header style={{ padding: "0rem 2rem", background: "white" }}>
        <Row type="flex" align="middle">
          <Col xs={{ span: 18 }} md={{ span: 5 }} lg={{ span: 5 }}>
            <Link to="/">
              <RumbleLogoDark css={logoCss} />
            </Link>
          </Col>
          {isAuthorized ? (
            <Fragment>
              <Col
                xs={{ span: 0 }}
                md={{ span: 15, offset: 0 }}
                lg={{ span: 14, offset: 0 }}
                xl={{ span: 15, offset: 0 }}
              >
                <NavMenu
                  emCss={hMenuCss}
                  handleIasLogin={handleIasLogin}
                  handleSelectDealer={handleSelectDealer}
                  handleSelectDealerForAnalytics={
                    handleSelectDealerForAnalytics
                  }
                  shouldHideDealerDropDown={shouldHideDealerDropDown} //
                  shouldDisableDealerMenu={shouldDisableDealerMenu} //
                  dealerList={dealerList} //
                />
              </Col>
              <Col
                xs={{ span: 0 }}
                md={{ span: 2 }}
                lg={{ span: 2, offset: 3 }}
                xl={{ span: 1 }}
                css={profileCss}
              >
                <Menu
                  mode="horizontal"
                  css={subMenuCss}
                  selectedKeys={selectedKeys}
                >
                  <SubMenu
                    css={profileIconCss}
                    title={<Avatar src={profileLogo} size="large" />}
                  >
                    <Item
                      onClick={() => {
                        logout();
                        history.push("/");
                      }}
                    >
                      Logout
                    </Item>
                  </SubMenu>
                </Menu>
              </Col>
            </Fragment>
          ) : (
            <Col xs={{ span: 0 }} md={{ span: 6, offset: 13 }}>
              <Menu css={loginCss} mode="horizontal">
                <Item>
                  <Link to="/login">Log In</Link>
                </Item>
                <Item>
                  <a href={`${envList.SIGN_UP_SF_LINK}`}>Sign Up</a>
                </Item>
              </Menu>
            </Col>
          )}
          <Col xs={{ span: 4, push: 2 }} md={{ span: 0 }}>
            <Button
              type="link"
              css={navIconCss}
              block={true}
              onClick={toggleMobileMenu}
            >
              <Icon
                type="menu-fold"
                style={{ color: color.textBlack, fontSize: "1.5rem" }}
              />
            </Button>
          </Col>
        </Row>
      </Header>
      <MenuDrawer
        isAuthorized={isAuthorized}
        visible={visible}
        emCss={DrawerMenuCss}
        handleCancel={toggleMobileMenu}
        handleIasLogin={handleIasLogin}
        handleLogout={logout}
        handleSelectDealer={handleSelectDealer}
        handleSelectDealerForAnalytics={handleSelectDealerForAnalytics}
        shouldHideDealerDropDown={shouldHideDealerDropDown} //
        shouldDisableDealerMenu={shouldDisableDealerMenu} //
        dealerList={dealerList} //
      />
    </Fragment>
  );
};

const MenuDrawer: FC<any> = ({
  visible,
  emCss,
  handleCancel,
  handleIasLogin,
  handleLogout,
  shouldHideDealerDropDown,
  shouldDisableDealerMenu,
  handleSelectDealer,
  handleSelectDealerForAnalytics,
  dealerList,
  isAuthorized,
}) => {
  const history = useHistory();
  const logout = () => {
    handleLogout();
    handleCancel();
    history.push("/");
  };

  return (
    <Drawer
      placement="right"
      height="100%"
      closable={false}
      onClose={handleCancel}
      visible={visible}
      bodyStyle={{ padding: "1rem 0", overflowX: "hidden" }}
    >
      <Row>
        <Col>
          <Button
            type="link"
            block={true}
            onClick={handleCancel}
            css={css`
              ${navIconCss};
              padding-right: 2rem;
            `}
          >
            <Icon
              type="menu-unfold"
              style={{ color: color.textBlack, fontSize: "1.5rem" }}
            />
          </Button>
        </Col>
      </Row>
      <Row
        css={css`
          li {
            font-weight: 600;
            padding: 0px;
          }
        `}
      >
        <Col>
          <Divider style={{ margin: "10px" }} />
          {isAuthorized ? (
            <Fragment>
              <NavMenu
                mode="inline"
                emCss={emCss}
                handleIasLogin={handleIasLogin}
                shouldHideDealerDropDown={shouldHideDealerDropDown}
                shouldDisableDealerMenu={shouldDisableDealerMenu}
                handleSelectDealer={handleSelectDealer}
                handleSelectDealerForAnalytics={handleSelectDealerForAnalytics}
                dealerList={dealerList}
                onClick={handleCancel}
              />
              <Divider style={{ margin: "10px" }} />
            </Fragment>
          ) : null}
          {isAuthorized ? (
            <Menu css={emCss} mode="inline">
              <Item onClick={logout}>Log Out</Item>
            </Menu>
          ) : (
            <Menu css={emCss} mode="inline">
              <Item onClick={handleCancel}>
                <a href={`${envList.SIGN_UP_SF_LINK}`}>Sign Up</a>
              </Item>
              <Item onClick={handleCancel}>
                <Link to="/login">Log In</Link>
              </Item>
            </Menu>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};

const NavMenu: FC<any> = ({
  mode = "horizontal",
  emCss,
  handleIasLogin,
  handleSelectDealer,
  shouldHideDealerDropDown,
  handleSelectDealerForAnalytics,
  shouldDisableDealerMenu,
  dealerList,
  onClick,
}) => {
  const handleOnclick = () => {
    if (!onClick) return;
    onClick();
  };

  const ROUTE_KEYS: any = {
    "/help": "help",
    "/create-motorcycle-offer": "offer",
    "/create-car-offer": "offer",
    "/sell": "sell",
    "/account": "account",
    "/analytics": "analytics",
    "/analytics/dealership": "analytics",
    "/analytics/click-through-rate": "analytics",
    "/analytics/inventory-metric": "analytics",
    "/analytics/advanced-inventory-metric": "analytics",
    "/inventory": "inventory",
    "/users": "users",
    "/leads": "leads",
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const selectedDealerAccounts = useSelector(
    (state: SystemState) => state.dealershipAnalytic.selectedDealerAccounts
  );
  const selectedDealerAccountsForInventory = useSelector(
    (state: SystemState) =>
      state.dealershipAnalytic.selectedDealerAccountsForInventory
  );

  const selectedDealerAccountId = useSelector(
    (state: SystemState) => state.auth.selectedDealerAccountId
  );
  const selectedAccountForAccountDetails = useSelector(
    (state: SystemState) =>
      state.accountDetails.selectedAccountForAccountDetails
  );
  const IsInternalDealer = useSelector(
    (state: SystemState) => state.auth.IsInternalDealer);
  const selectDealerForAnalytics = (accountIds: number[]) => {
    handleSelectDealerForAnalytics(accountIds);
    handleOnclick();
  };
  const handleDealerLogin = () => {
    history.push("/");
    handleIasLogin();
    handleOnclick();
  };

  const parentChildDealers = getParentChildDealerList(dealerList);
  const foundedLocation = ROUTE_KEYS[location.pathname];
  let selectedKeys = foundedLocation ? [foundedLocation] : undefined;

  if (foundedLocation === "analytics" || foundedLocation === "leads") {
    let keySuffix = selectedDealerAccounts.join("+");
    if (!selectedDealerAccounts.length && parentChildDealers.length) {
      keySuffix = parentChildDealers[0].childrenIds.join("+");
    }
    selectedKeys = [`${foundedLocation}-${keySuffix}`];
  }

  if (foundedLocation === "inventory") {
    const keySuffix = selectedDealerAccountsForInventory.join("+");
    selectedKeys = [`inventory-${keySuffix}`];
  }

  if (foundedLocation === "account") {
    selectedKeys = [`account-${selectedAccountForAccountDetails}`];
  }
  if (foundedLocation === "users") {
    selectedKeys = [`users-${selectedAccountForAccountDetails}`];
  }
  return (
    <Menu mode={mode} css={emCss} selectedKeys={selectedKeys}>
      <Item key="help" onClick={handleOnclick}>
        <Link to="/help">Help</Link>
      </Item>

      {shouldHideDealerDropDown ? null : (
        <SubMenu title="Switch Dealer" disabled={shouldDisableDealerMenu}>
          {dealerList &&
            dealerList.map((dealer: any, i: number) => (
              <Item
                key={i}
                className="active"
                onClick={() => handleSelectDealer(dealer.AccountId)}
              >
                {dealer.AccountId === selectedDealerAccountId ? (
                  <b> {dealer.AccountName} </b>
                ) : (
                  dealer.AccountName
                )}
              </Item>
            ))}
        </SubMenu>
      )}
      {
        <Item >
        <a target="_blank" href={`${envList.RIDENOW_AUCTIONS_LINK}`}>Auctions</a>
      </Item>
      /* {hasPermission("DD_BUY_BID") ? (
        <Item onClick={handleDealerLogin}>Dealer Direct</Item>
      ) : null} */
      }
      <SubMenu
        key="offer"
        title="Create Offer"
        disabled={shouldDisableDealerMenu}
      >
        <Item key="/create-motorcycle-offer" onClick={handleOnclick}>
          <Link to="/create-motorcycle-offer">Motorcycle</Link>
        </Item>
        <Item onClick={handleOnclick}>
          <Link to="/create-boat-offer">Boat</Link>
        </Item>
        <Item onClick={handleOnclick}>
          <Link to="/create-car-offer">Car</Link>
        </Item>
      </SubMenu>

      <Item key="sell" onClick={handleOnclick}>
        <Link to="/sell">My Offers</Link>
      </Item>

      {/* {hasPermission("RO_LISTING") ? (
        <SubMenu
          key="analytics"
          title="Analytics"
          disabled={parentChildDealers.length === 0}
        >
          {parentChildDealers.map(parent => {
            return (
              <SubMenu
                key={`analytics-parent-${parent.AccountId}`}
                title={
                  <span style={{ paddingRight: 15 }}>
                    {" "}
                    {parent.AccountName}{" "}
                  </span>
                }
              >
                <Item
                  key={`analytics-${parent.childrenIds.join("+")}`}
                  onClick={() => selectDealerForAnalytics(parent.childrenIds)}
                >
                  <Link to="/analytics"> [All Dealers]</Link>
                </Item>
                {parent.children.length > 1 &&
                  parent.children.map(child => {
                    return (
                      <Item
                        key={`analytics-${child.AccountId}`}
                        onClick={() =>
                          selectDealerForAnalytics([child.AccountId])
                        }
                      >
                        <Link to="/analytics"> {child.AccountName}</Link>
                      </Item>
                    );
                  })}
              </SubMenu>
            );
          })}
        </SubMenu>
      ) : null} */}
      {hasPermission("RO_LISTING") ? (
        <SubMenu
          key="inventory"
          title="Inventory"
          disabled={parentChildDealers.length === 0}
        >
          {parentChildDealers.map(parent => {
            return (
              <SubMenu
                key={`inventory-parent-${parent.AccountId}`}
                title={
                  <span style={{ paddingRight: 15 }}>
                    {" "}
                    {parent.AccountName}{" "}
                  </span>
                }
              >
                <Item
                  key={`inventory-${parent.childrenIds.join("+")}`}
                  onClick={() =>
                    dispatch(setSelectedDealerForInventory(parent.childrenIds))
                  }
                >
                  <Link to="/inventory"> [All Dealers] </Link>
                </Item>
                {parent.children.length > 1 &&
                  parent.children.map(child => {
                    return (
                      <Item
                        key={`inventory-${child.AccountId}`}
                        onClick={() =>
                          dispatch(
                            setSelectedDealerForInventory([child.AccountId])
                          )
                        }
                      >
                        <Link to="/inventory"> {child.AccountName}</Link>
                      </Item>
                    );
                  })}
              </SubMenu>
            );
          })}
        </SubMenu>
      ) : null}
      {hasPermission("RO_LISTING") ? (
        <SubMenu
          key="leads"
          title="Leads"
          disabled={parentChildDealers.length === 0}
        >
          {parentChildDealers.map(parent => {
            return (
              <SubMenu
                key={`leads-parent-${parent.AccountId}`}
                title={
                  <span style={{ paddingRight: 15 }}>
                    {" "}
                    {parent.AccountName}{" "}
                  </span>
                }
              >
                <Item
                  key={`leads-${parent.childrenIds.join("+")}`}
                  onClick={() => selectDealerForAnalytics(parent.childrenIds)}
                >
                  <Link to="/leads"> [All Dealers]</Link>
                </Item>
                {parent.children.length > 1 &&
                  parent.children.map(child => {
                    return (
                      <Item
                        key={`leads-${child.AccountId}`}
                        onClick={() =>
                          selectDealerForAnalytics([child.AccountId])
                        }
                      >
                        <Link to="/leads"> {child.AccountName}</Link>
                      </Item>
                    );
                  })}
              </SubMenu>
            );
          })}
        </SubMenu>
      ) : null}
      <SubMenu
        key="users"
        title="Users"
        disabled={parentChildDealers.length === 0}
      >
        {parentChildDealers.map(parent => {
          return (
            <SubMenu
              key={`users-parent-${parent.AccountId}`}
              title={
                <span style={{ paddingRight: 15 }}>{parent.AccountName} </span>
              }
            >
              {parent.children.map(child => {
                return (
                  <Item
                    key={`users-${child.AccountId}`}
                    onClick={() =>
                      dispatch(
                        setSelectedAccountForAccountDetails(child.AccountId)
                      )
                    }
                  >
                    <Link to="/users"> {child.AccountName}</Link>
                  </Item>
                );
              })}
            </SubMenu>
          );
        })}
      </SubMenu>
      <SubMenu
        key="account"
        title="Account"
        disabled={parentChildDealers.length === 0}
      >
        {parentChildDealers.map(parent => {
          return (
            <SubMenu
              key={`account-parent-${parent.AccountId}`}
              title={
                <span style={{ paddingRight: 15 }}>{parent.AccountName} </span>
              }
            >
              {parent.children.map(child => {
                return (
                  <Item
                    key={`account-${child.AccountId}`}
                    onClick={() =>
                      dispatch(
                        setSelectedAccountForAccountDetails(child.AccountId)
                      )
                    }
                  >
                    <Link to="/account"> {child.AccountName}</Link>
                  </Item>
                );
              })}
            </SubMenu>
          );
        })}
      </SubMenu>
        {IsInternalDealer && <Item>
          <Link to="/nadadecoder">NADA Valuation</Link>
        </Item>}
      {IsInternalDealer &&
        <SubMenu
          key="CyclePro"
          title="Cycle Pro "
        >
          <Item key="/cycleprosearch" onClick={handleOnclick}>
            <Link
              to={{
                pathname: "/cycleprosearch",
                state: {
                  from: "Home",
                  Tab: "1",
                },
              }}>Search</Link>
          </Item>
          <Item key="/cycleprohistory" onClick={handleOnclick}>
            <Link to={{
              pathname: "/cycleprosearch",
              state: {
                from: "Home",
                Tab: "2",
              }
            }}>History</Link>
          </Item>
        </SubMenu>}
    </Menu>
  );
};

export default AppHeader;
