import { Reducer } from "redux";

import {
  SET_VIN_DECODE_IS_FETCHING,
  SET_VIN_DECODE_DECODED_VIN,
  REMOVE_FROM_DECODED_VIN_LIST,
  SET_VIN_DECODE_RESET_STATUS,
  SET_VIN_DECODE_ERROR,
  RESET_VIN_DECODE_ERROR,
  ADD_TO_DECODED_VIN_LIST,
  RESET_VIN_DECODE_STATE,
  VinDecodeReducerState,
  SET_MODELLIST,
  SET_PAYLOAD,

} from '../type/VinDecodeTypes';

import { findAndSplitArr } from '../util'

const initialState: VinDecodeReducerState = {
  decodedVins: [],
  decodedVin: null,
  isFetching: false,
  resetStatus: "pending",
  errorMessage: "",
  modellist: [],
  payloadData: [],

}

const genericVinDecoderReducer: Reducer = (
  state = initialState,
  action: any
) => {
  const { type, payload } = action;

  //console.log('%c Vindecode Reducer', 'color: purple', action, payload, state)

  switch (type) {
    case SET_VIN_DECODE_IS_FETCHING:
      return {
        ...state,
        isFetching: payload,
      };
    case SET_VIN_DECODE_DECODED_VIN:
      return {
        ...state,
        decodedVin: payload,
      };

    case SET_VIN_DECODE_RESET_STATUS:
      return {
        ...state,
        resetStatus: payload,
      };

    case SET_VIN_DECODE_ERROR:
      return {
        ...state,
        errorMessage: payload,
      };

    case RESET_VIN_DECODE_ERROR:
      return {
        ...state,
        errorMessage: "",
      };

    case ADD_TO_DECODED_VIN_LIST:
      return {
        ...state,
        decodedVins: [...state.decodedVins, payload],
      };

    case REMOVE_FROM_DECODED_VIN_LIST:
      return {
        ...state,
        decodedVins: state.decodedVins.filter((i: any) => i.VIN !== payload)
      }
    case RESET_VIN_DECODE_STATE:
      return {
        ...initialState,
        decodedVins: state.decodedVins,
      };
    case SET_MODELLIST:
      return {
        ...state,
        modellist: [...state.modellist, payload]
      };
    case SET_PAYLOAD:
      return {
        ...state,
        payloadData: [...state.payloadData, payload]
      }

    default:
      return state;
  }

}

export default genericVinDecoderReducer;