export interface LeadState {
  readonly leadList: LeadItemResponse[];
  readonly leadListLoading: boolean;
  readonly leadListError: null | string;

  readonly lead: null | LeadResponseById;
  readonly leadLoading: boolean;
  readonly leadError: null | string;
  readonly leadNoData: boolean;

  readonly sellToRubmleonLoading: boolean;
  readonly sellToRubmleonError: null | string;
}

export interface LeadResponseById extends LeadItemResponse {
  Rating?: null | string;
  CustomerNotes?: null | string;
  Images?: null | string[];
}

export interface LeadItemResponse {
  AccountId: number;
  LeadId: number;
  AccountName: string;
  LeadType: string;
  CreatedDate: string;
  LeadStatus: string;
  ContactNumber: string;
  CreatedUserFirstName: string;
  RatingLabel: string;
  CreatedUserLastName: string | null;
  CreatedUserEmail: string;
  ItemYear: string;
  Make: string;
  Model: string;
  Vin: string;
  CashOfferValue: string;
  ItemType: null | string;
}

export const SET_CLEAR_LEAD_MESSAGES = "SET_CLEAR_LEAD_MESSAGES";

export const SET_GET_LEAD_LOADING = "SET_GET_LEAD_LOADING";
export const SET_GET_LEAD_FAILED = "SET_GET_LEAD_FAILED";
export const SET_GET_LEAD_SUCCESS = "SET_GET_LEAD_SUCCESS";
export const SET_NO_DATA_FOR_LEAD = "SET_NO_DATA_FOR_LEAD";

export const SET_GET_LEAD_LIST_LOADING = "SET_GET_LEAD_LIST_LOADING";
export const SET_GET_LEAD_LIST_FAILED = "SET_GET_LEAD_LIST_FAILED";
export const SET_GET_LEAD_LIST_SUCCESS = "SET_GET_LEAD_LIST_SUCCESS";

export const SET_SELL_TO_RUMBLEON_LOADING = "SET_SELL_TO_RUMBLEON_LOADING";
export const SET_SELL_TO_RUMBLEON_FAILED = "SET_SELL_TO_RUMBLEON_FAILED";

interface SetClearLeadMessages {
  type: typeof SET_CLEAR_LEAD_MESSAGES;
}

interface SetGetLeadLoading {
  type: typeof SET_GET_LEAD_LOADING;
  payload: boolean;
}

interface SetGetLeadSuccess {
  type: typeof SET_GET_LEAD_SUCCESS;
  payload: LeadResponseById | null;
}

interface SetGetLeadFailed {
  type: typeof SET_GET_LEAD_FAILED;
  payload: string | null;
}

interface SetNoDataForLead {
  type: typeof SET_NO_DATA_FOR_LEAD;
  payload: boolean;
}

interface SetGetLeadListLoading {
  type: typeof SET_GET_LEAD_LIST_LOADING;
  payload: boolean;
}

interface SetGetLeadListSuccess {
  type: typeof SET_GET_LEAD_LIST_SUCCESS;
  payload: LeadItemResponse[];
}

interface SetGetLeadListFailed {
  type: typeof SET_GET_LEAD_LIST_FAILED;
  payload: string | null;
}

interface SetSellToRumbleOnLoading {
  type: typeof SET_SELL_TO_RUMBLEON_LOADING;
  payload: boolean;
}

interface SetSellToRumbleOnFailed {
  type: typeof SET_SELL_TO_RUMBLEON_FAILED;
  payload: string | null;
}

export type LeadAction =
  | SetClearLeadMessages
  | SetGetLeadLoading
  | SetGetLeadSuccess
  | SetGetLeadFailed
  | SetNoDataForLead
  | SetGetLeadListLoading
  | SetGetLeadListSuccess
  | SetGetLeadListFailed
  | SetSellToRumbleOnLoading
  | SetSellToRumbleOnFailed;
