export interface InventoryMetricState {
  readonly isLoading: boolean;
  readonly errorMessage: string | null;
  readonly impressionsVsInventories: ImpressionVsClickData[];
  readonly clicksVsInventories: ImpressionVsClickData[];

  readonly conditionImpression: ConditionalData;
  readonly conditionClick: ConditionalData;
}

export const SET_INVENTORY_METRIC_LOADING = "SET_INVENTORY_METRIC_LOADING";
export const SET_INVENTORY_METRIC_FAILED = "SET_INVENTORY_METRIC_FAILED";

export const SET_CONDITIONAL_IMPRESSIONS_SUCCESS =
  "SET_CONDITIONAL_IMPRESSIONS_SUCCESS";
export const SET_CONDITIONAL_CLICKS_SUCCESS = "SET_CONDITIONAL_CLICKS_SUCCESS";

export const SET_GET_INVENTORY_METRIC_SUCCESS =
  "SET_GET_INVENTORY_METRIC_SUCCESS";
export const SET_GET_INVENTORY_CLICK_SUCCESS =
  "SET_GET_INVENTORY_CLICK_SUCCESS";

export interface ImpressionVsClickData {
  key: string;
  value: number;
}

export interface ConditionalData {
  xLabels: string[];
  dataLabels: string[];
  data: Array<number[]>;
  download: object[];
}

interface SetInventoryMetricLoadingAction {
  type: typeof SET_INVENTORY_METRIC_LOADING;
  payload: boolean;
}

interface SetInventoryMetricErrorAction {
  type: typeof SET_INVENTORY_METRIC_FAILED;
  payload: string | null;
}

interface SetGetInventoryImpressionSuccess {
  type: typeof SET_GET_INVENTORY_METRIC_SUCCESS;
  payload: ImpressionVsClickData[];
}

interface SetGetInventoryClickSuccess {
  type: typeof SET_GET_INVENTORY_CLICK_SUCCESS;
  payload: ImpressionVsClickData[];
}

interface SetConditionalImpressionSuccess {
  type: typeof SET_CONDITIONAL_IMPRESSIONS_SUCCESS;
  payload: ConditionalData;
}

interface SetConditionalClickSuccess {
  type: typeof SET_CONDITIONAL_CLICKS_SUCCESS;
  payload: ConditionalData;
}

export type InventoryMetricAction =
  | SetInventoryMetricLoadingAction
  | SetInventoryMetricErrorAction
  | SetGetInventoryImpressionSuccess
  | SetGetInventoryClickSuccess
  | SetConditionalImpressionSuccess
  | SetConditionalClickSuccess;
