export interface SignupPayload {
  readonly AccountTypeId?: number;
  readonly City: string;
  readonly ClientId: "rumbleon-consumer";
  readonly DealerLicenseImgUri: string | null;
  readonly DealerShipLicenseExp: string | null;
  readonly DealerShipLicenseNo: string | null;
  readonly DealerShipName: string | null;
  readonly Email: string;
  readonly FirstName: string;
  readonly LastName: string;
  readonly Mobile: string;
  readonly Password: string;
  readonly State: string;
  readonly StateSalesTaxImgUri: string | null;
  readonly StreetAddress: string;
  readonly UserStatus: "Awaiting Review";
  readonly UserStatusId: 6;
  readonly UserTypeId: "2";
  readonly Zip: string;
  readonly auctionAccessNumbers: {
    aarepid: string;
    name: string;
    dealerNumber: string;
    city: string;
    state: string;
  }[];
}

export interface SignupState {
  readonly isSignupSuccess: boolean;
  readonly isSignupLoading: boolean;
  readonly signupFailedMessage: string;
}

export const SET_SIGNUP_SUCCESS = "SET_SIGNUP_SUCCESS";
export const SET_SIGNUP_LOADING = "SET_SIGNUP_LOADING";
export const SET_SIGNUP_FAILED_MESSAGE = "SET_SIGNUP_FAILED_MESSAGE";
export const RESET_SIGNUP_STATE = "RESET_SIGNUP_STATE";

interface SetSignupSuccessAction {
  type: typeof SET_SIGNUP_SUCCESS;
  payload: boolean;
}

interface SetSignupLoadingAction {
  type: typeof SET_SIGNUP_LOADING;
  payload: boolean;
}

interface SetSignupFailedMessageAction {
  type: typeof SET_SIGNUP_FAILED_MESSAGE;
  payload: string;
}

interface ResetSignupStateAction {
  type: typeof RESET_SIGNUP_STATE;
}

export type SignupActionTypes =
  | SetSignupSuccessAction
  | SetSignupLoadingAction
  | SetSignupFailedMessageAction
  | ResetSignupStateAction;
